import React, { useEffect, useRef, useState } from 'react'
import config from '../../common/config'

export default function useInactivity({ onInActive, onActive } = {}) {

    const timeOut = useRef()
    const wasInactive = useRef(false)
    const oneDay = 86400000

    useEffect(() => {
        window.addEventListener('wheel', handleInactivity)
        window.addEventListener('mousemove', handleInactivity)
        window.addEventListener('keydown', handleInactivity)
        return () => {
            clearTimeout(timeOut.current)
            window.removeEventListener('wheel', handleInactivity)
            window.removeEventListener('mousemove', handleInactivity)
            window.removeEventListener('keydown', handleInactivity)
        }
    }, [])

    function handleInactivity() {
        wasInactive.current && onActive && onActive()
        wasInactive.current = false
        clearTimeout(timeOut.current)
        timeOut.current = setTimeout(() => {
            onInActive && onInActive()
            wasInactive.current = true
        }, config._Inactivity_timeOut || oneDay);
    }

}
