import React, { useEffect } from 'react'

export default function usePasswordProtect({ passwordRef, eyeIconRef = {} }) {

    useEffect(() => {
        let observer
        const passwordInput = passwordRef.current;
        if (passwordInput) {
            observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'type') {
                        let input = passwordInput.querySelector('input')
                        if (input?.type !== 'password' && !eyeIconRef.current) {
                            input.type = 'password'
                        }
                    }
                })
            })
            observer.observe(passwordInput, {
                attributes: true,
                characterData: true,
                childList: true,
                subtree: true,
            })
        }
        return () => {
            observer?.disconnect()
        }
    }, [passwordRef.current])

    return null
}
