import React, { useState } from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import Table from '../../_common/_components/Table/Table'
import Button from '../../_common/_components/Button/Button'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'
import Select from '../../_common/_components/Select/Select'
import useFilter from '../../_common/_Hooks/useFilter'
import useFetch from '../../_common/_Hooks/useFetch'
import { useNavigate, useParams } from 'react-router-dom'
import ActionButtons from '../../_common/_components/ActionButtons/ActionButtons'
import { IMG_BASE_URL } from '../../config'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import { toast } from 'react-toastify'
import useTranslate from '../../_common/_Hooks/useTranslate'

export default function Assign_volunteers() {

    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslate()

    const [assigned, setAssigned] = useState([])

    const assignments = [
        { label: 'Primary', value: 'primary' },
        { label: 'Backup', value: 'backup' },
    ]

    // *********************************************************************************************************************
    const { res: approvedVolunteers_res, req: getVolunteers } = useFetch({
        endpoint: 'volunteer/getApprovedVolunteer',
        method: 'post',
    })
    // *********************************************************************************************************************
    const { req: assign } = useFetch({
        endpoint: 'event/assignVolunteer',
        method: 'post',
        toast: true
    })
    // *********************************************************************************************************************
    const { req: exportVolunteers } = useFetch({
        endpoint: 'volunteer/getApprovedVolunteerExport',
        method: 'post',
        type: 'raw',
        download: true
    })
    // *********************************************************************************************************************
    const approvedVolunteers = approvedVolunteers_res?.data
    const pageDetails = approvedVolunteers_res?.page
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate, initialFilter } = useFilter({
        initialFilter: { page: 0, perPage: 10, event: id },
        Fetch: (filter) => {
            volunteerGetter(filter)
        },
    });

    function volunteerGetter(filter) {
        getVolunteers(filter, (res) => {
            if (res.status) {
                res.data.map(item => {
                    assignmentHandler({ value: item.assigned_status }, item)
                })
            }
        })
    }

    function assignmentHandler(e, vlntr) {
        setAssigned(s => ({ ...s, [vlntr._id]: e.value }))
    }

    function submitHandler() {
        if (Object.values(assigned).filter(a => a).length === 0) {
            toast.warning(t('No Volunteers Assigned'), { autoClose: 3000, position: 'bottom-center' })
            return
        }
        let rs = Object.entries(assigned).reduce((a, c) => {
            c[1] === 'primary' && a.primary.push(c[0])
            c[1] === 'backup' && a.backup.push(c[0])
            return a
        }, { primary: [], backup: [] })
        assign({
            id,
            backup_volunteers: rs.backup,
            primary_volunteers: rs.primary
        }, () => {
            navigate('/volunteer/events/event_detail/' + id)
        })
    }

    return (
        <Page className='Event_detail'>

            <Page.Header >
                <Title />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title='Assign Volunteers' titleEndElement={
                    approvedVolunteers?.length > 0 && <Button css={'1.1.1'} height={38} content='Export List' br={5} icon={'/assets/icons/upload1.svg'} onClick={() => exportVolunteers(filterData)} />
                }>
                    <Table
                        className='as-vl-tbl'
                        css={1.1}
                        _css={{ search: 2, pagination: 1, entries: 1 }}
                        titles={[
                            { title: 'First Name', key: 'first_name', sort: true },
                            { title: 'Last Name', key: 'last_name', sort: true },
                            { title: 'Phone Number', key: 'phone_number', sort: true, dir: 'ltr', d_align: 'right' },
                            { title: 'Email', key: 'email', sort: true },
                            { title: 'Emirates ID', key: 'eid' },
                            // { title: 'Status', key: 'status', sort: true },
                            { title: 'Assignment', key: 'assignment', sort: true },
                        ]}
                        content={approvedVolunteers?.map((r) => {
                            return {
                                first_name: r.first_name,
                                last_name: r.last_name,
                                phone_number: r?.phone_number ? ((r?.customer_contact_code || '+971') + ' ' + r?.phone_number) : '-',
                                email: r.email,
                                eid: r.eid.length > 0 && <LabelValue valueIcon='/assets/icons/id.svg' iconOnly url={`${IMG_BASE_URL}volunteer/${r.eid}`} iconHeight={35} />,
                                // status: <StatusBox css={1} status={r.status.request_status} />,
                                assignment: (r.is_available || (r.assigned_status === 'primary' || r.assigned_status === 'backup'))
                                    ? <Select css={1} width={200} field='hi'
                                        content={{ op: assignments, lb: 'label', vl: 'value' }}
                                        value={assigned[r._id]}
                                        onChange={(e) => assignmentHandler(e, r)}
                                        showCloseBtn

                                    />
                                    : 'Not Available',
                            }
                        })}
                        // moreFilters={
                        //     <Select css={1} width={200} mr={'auto'} ml={10} height={43} label='Request Type' field='request_type'
                        //         content={{ op: [{}], lb: 'label', vl: 'value' }}
                        //     />
                        // }
                        onRowClick={(e) => { }}
                        pageDetails={pageDetails}
                        filterHandler={filterHandler}
                        filterData={filterData}
                    />
                </TitleBar>
            </Page.Body>

            <ActionButtons _css={{ button: 1 }}>
                <Button css={1} content='Cancel' onClick={() => volunteerGetter(initialFilter)} />
                <Button css={1} content='Submit' onClick={submitHandler} />
            </ActionButtons>
        </Page >
    )
}
