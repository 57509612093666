import React, { useEffect } from 'react'
import useFetch from '../_common/_Hooks/useFetch'
import { AppLoggedOut, setApiHeaders, storageUpdate, storeUpdate, translationData } from '../_common'
import useInitialAPIs from '../_common/_Hooks/useInitialAPIs'
import { setPermissions } from '../_common/App'
import useTranslate from '../_common/_Hooks/useTranslate'
import { io } from 'socket.io-client'
import { API_BASE_URL, tokenRefreshInterval } from '../config'
import { toast } from 'react-toastify'
import axios from 'axios'
import config from '../common/config'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

// export const socket = io(API_BASE_URL)
// export const socket = io('http://24.199.89.100',{transports: ['websocket']})
export const socket = io(API_BASE_URL, {
    transports: ["websocket"]
});

const sound = document.createElement('audio')
sound.src = '/assets/notification.mp3'

function refresher() {
    let refreshToken = localStorage.getItem('refreshToken')
    refreshToken && axios
        .post(`${config.__api_base_url}login/verifyRefresh`, { refreshToken })
        .then(
            (res) => {
                const token = res.data.data.accessToken
                const csrfToken = res.data.data.csrfToken
                localStorage.setItem('token', token)
                localStorage.setItem('csrf', csrfToken)
            }
        )
        .catch((error) => {
            if (error.response?.status === 401) {
                AppLoggedOut()
            }
        })
}

export default function InitialApis() {

    const navigate = useNavigate()
    const { setLanguage, lang } = useTranslate()

    let interval
    useEffect(() => {
        refresher()
        interval = setInterval(() => {
            refresher()
        }, config._tokenRefreshInterval);
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        socket.on('notification', handleNotification)
        return () => socket.off('notification', handleNotification)
    }, [])

    function handleNotification(res) {
        sound.play()
        console.log('notification', res)
        storageUpdate({ department: res.deptObj })
        toast.info(res.message, {
            position: 'bottom-right',
            onClick: (e) => {
                navigate('/incident/requests/view_request/' + res.requestId, { state: { department: res.deptObj._id } })
            }
        })
    }

    // *********************************************************************************************************************
    const { req: getDepartments } = useFetch({
        endpoint: 'common/getDepartmentsOfCurrentUser',
        method: 'get',
        type: 'raw',
        toast: false,
    })
    // *********************************************************************************************************************
    const { req: getUser } = useFetch({
        endpoint: 'common/getCurrentUser',
        method: 'get',
        type: 'raw',
        toast: false,
    })
    // *********************************************************************************************************************
    const { req: getTexts } = useFetch({
        endpoint: 'translate/getTexts',
        method: 'post',
    })
    // *********************************************************************************************************************
    const { req: insertText } = useFetch({
        endpoint: 'translate/insertText',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: getStatuses } = useFetch({
        endpoint: 'common/getAllStatus',
        method: 'get',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: userStatus } = useFetch({
        endpoint: "user/updateUserOnlineStatus",
        method: "put",
        type: "raw",
        privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: getFlags } = useFetch({
        endpoint: 'common/countryFlagList',
        method: 'get',
        privateLoader: true
    })
    // *********************************************************************************************************************

    return useInitialAPIs({
        waitingApis: [

            () => getDepartments({}, res => {
                if (res.data?.default_department?._id) {
                    setApiHeaders(s => s?.deptid ? s : { deptid: res.data.default_department?._id })
                    storeUpdate(s => s?.department ? s : { department: res.data.default_department })
                    storeUpdate({ departments: res.data?.department })
                    localStorage.setItem('sckd', JSON.stringify({ userId: res.data._id, role: res.data.role, dept: res.data.department }))
                    socket.emit('userConnected', { userId: res.data._id, role: res.data.role, dept: res.data.department })
                }
            }),

            () => getUser({}, res => {
                setPermissions({
                    access: res.data.role,
                    instagram_access: res.data?.upload_instagram_feeds || res.data?.view_instagram_feeds || res.data?.approve_instagram_feeds,
                    manage_volunteer_with_us: res.data.manage_volunteer_with_us,
                    manage_lecture_request: res.data.manage_lecture_request
                })
                storeUpdate({ user: res.data })
                if (!res.data.is_english) {
                    setLanguage('ar')
                }
                if (res.data.role === 'viewer') {
                    navigate('/dashboard_viewer')
                }
            }),

            () => getTexts({ is_structured: true }, res => {
                translationData(res.data)
            }),

        ],

        nonWaitingApis: [

            () => getFlags({}, (res) => {
                storeUpdate({ flags: res.data })
            }),

            () => getStatuses({}, res => {
                if (!res.status) return
                let obj = res.data?.reduce((acc, crr) => ({ ...acc, [crr.handle_key]: crr }), {})
                storeUpdate({ statuses: obj })
            })

        ],

        addTranslation: (data) => {
            insertText(data)
        },

        onInActive: (e) => {
            userStatus({ online_status: 'Away' })
            socket.disconnect()
        },

        onActive: () => {
            userStatus({ online_status: 'Available' })
            socket.connect()
            socket.emit('userConnected', JSON.parse(localStorage.getItem('sckd')))
        }
    })
}