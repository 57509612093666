import React, { useContext, useEffect, useState } from 'react'
import './Title.scss'
import useTranslate from '../../../_common/_Hooks/useTranslate'
import { CommonContext, classMaker } from '../..'

export default function Title({ className, css, title }) {

  const [text, setText] = useState('')
  const { t } = useTranslate()

  const { navbarObj } = useContext(CommonContext)

  const pathObj = objGetter()

  function objGetter() {
    if (navbarObj[window.location.pathname.slice(1)] === undefined) {
      return navbarObj[window.location.pathname.replace(/\/[a-zA-Z0-9]*$/, '').slice(1)]
    }
    if (window.location.pathname === '/') {
      return navbarObj['/']
    }
    return navbarObj[window.location.pathname.slice(1)]
  }

  useEffect(() => {
    setText(title)
    return () => {
      setText('')
    }
  }, [title])

  return (
    <h1 id='Title' className={classMaker(className, css, 'Title')}>
      {
        title
          ? t(text)
          : t(pathObj?.title)
      }
    </h1>
  )
}

Title.displayName = 'Title'