import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import Input from '../../_common/_components/Input/Input'
import useForm from '../../_common/_Hooks/useForm'
import Grid from '../../_common/_components/Grid/Grid'
import Button, { Buttons } from '../../_common/_components/Button/Button'
import useFetch from '../../_common/_Hooks/useFetch'

export default function Email() {

    const { formPlug, validate, inputs, setInputs } = useForm()

    // *********************************************************************************************************************
    const { req: getDetails } = useFetch({
        endpoint: 'getEmailCredentials',
        method: 'get',
        selfCall: true,
        success: (res) => {
            setInputs({
                id: res.data._id,
                userName: res.data.userName,
                password: res.data.password,
                host: res.data.host,
                port: res.data.port,
            })
        }
    })
    // *********************************************************************************************************************
    const { req: testConnection } = useFetch({
        endpoint: 'testEmailConnection',
        method: 'get',
        toast: true
    })
    // *********************************************************************************************************************
    const { req: save } = useFetch({
        endpoint: 'emailCredentialsAddOrUpdate',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // *********************************************************************************************************************
    async function submitHandler() {
        if (!await validate()) return
        save({
            id: inputs.id,
            userName: inputs.userName,
            password: inputs.password,
            host: inputs.host,
            port: inputs.port,
        }, () => {
            getDetails()
        })
    }
    function testConnectionHandler() {
        testConnection()
    }
    return (
        <Page>
            <Page.Header>
                <Title title='Email' />
                <Breadcrumb />
            </Page.Header>
            <Page.Body css={1}>
                <TitleBar css={1} title='Email Details' toggleButton >
                    <Grid columns={2} width={'50%'}>
                        <Input
                            css={1}
                            label='Username'
                            field={'userName'}
                            inputSocket={formPlug}
                        />
                        <Input
                            css={1}
                            label='Password'
                            field={'password'}
                            inputSocket={formPlug}
                            type='password'
                        />
                        <Input
                            css={1}
                            label='Host'
                            field={'host'}
                            inputSocket={formPlug}
                        />
                        <Input
                            css={1}
                            label='Port'
                            field={'port'}
                            inputSocket={formPlug}
                            type='number'
                        />
                    </Grid>
                    <Buttons mt={50} mb={30}>
                        <Button right content="Save" css={1} onClick={submitHandler} />
                        <Button right content="Test Connection" css={1.1} onClick={testConnectionHandler} />
                    </Buttons>
                </TitleBar>
            </Page.Body>
        </Page>
    )
}