import React, { useEffect } from 'react'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Table from '../../_common/_components/Table/Table'
import useFetch from '../../_common/_Hooks/useFetch'
import useTranslate from '../../_common/_Hooks/useTranslate'
import List from '../../_common/_components/List/List'
import Page from '../../_common/_components/Page/Page'
import useFilter from '../../_common/_Hooks/useFilter'

export default function AgentListing() {

    const { _lang } = useTranslate()

    // *********************************************************************************************************************
    const { req: getAgents, res: agents_res } = useFetch({
        endpoint: 'agent/getAllAgents',
        method: 'post',
        type: 'formdata',
    })
    // *********************************************************************************************************************
    let agents = agents_res?.data
    let pageDetails = agents_res?.page
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getAgents
    })

    return (
        <Page>

            <Page.Header>
                <Title title='Agent List' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body>
                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Employee ID', key: 'employee_id', sort: true },
                        { title: 'Employee Name', key: 'first_name', sort: true },
                        { title: 'Department', key: 'department_name', sort: true }
                    ]}
                    content={agents?.map(a => {
                        return {
                            employee_id: a.employee_id,
                            first_name: a['first_name' + _lang],
                            department_name: <List items={a.department} item={(e) => e['department_name' + _lang]} />,
                        }
                    })}
                    pageDetails={pageDetails}
                    filterData={filterData}
                    filterHandler={filterHandler}
                />
            </Page.Body>

        </Page>
    )
}
