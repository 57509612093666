import React, { useState } from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import List from '../../_common/_components/List/List'
import useFetch from '../../_common/_Hooks/useFetch'
import useTranslate from '../../_common/_Hooks/useTranslate'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import Button, { Buttons } from '../../_common/_components/Button/Button'

export default function Notifications() {

    const { t, _lang } = useTranslate()

    const [alerts, setAlerts] = useState([])

    // *********************************************************************************************************************
    const { req: getAlerts, res: getAlerts_res } = useFetch({
        endpoint: 'common/getErrorLogs',
        method: 'post',
        type: 'raw',
        selfCall: { limit: 10 },
        success: (res) => {
            res.page.page === 0 && setAlerts(res.data)
        }
    })
    // *********************************************************************************************************************

    function loadMoreHandler() {
        getAlerts({ page: getAlerts_res?.page?.page + 1, limit: 10 }, (res) => {
            setAlerts(s => {
                return [...s, ...res.data]
            })
        })
    }

    console.log({ alerts })

    return (
        <Page className='Alerts'>
            <Page.Header>
                <Title title="Alerts" />
                <Breadcrumb content={[
                    { path: '/', name: 'Main Menu' },
                    { name: 'Alerts' }
                ]} />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title='Alerts'>
                    {alerts?.length > 0
                        ? <List css={3.1} items={alerts}>
                            {
                                (datom) => {
                                    return (
                                        <>
                                            <img src="/assets/icons/alert.svg" alt="" />
                                            <div>
                                                <p className='title'>{datom.level}</p>
                                                <p className='desc'>{datom.message}</p>
                                            </div>
                                            <p className='on'><DateViewer dateTime={datom.updated_date} timeOnly /></p>
                                        </>
                                    )
                                }
                            }
                        </List>
                        : <p style={{ textAlign: 'center' }}>{t('No data available')}</p>
                    }
                    {getAlerts_res?.page?.hasNext &&
                        <Buttons center mb={30} mt={10}>
                            <Button css={1} content='Load More' onClick={loadMoreHandler} />
                        </Buttons>
                    }
                </TitleBar>
            </Page.Body>
        </Page>
    )
}
