import React from 'react'
import './Dashboard.scss'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import { Agents, Calls, ChartBarH, ChartBarV, ChartDoughnut, ChartPie, ChartPolar, Counts, Filter, InstagramFeeds } from './DashboardItems'
import useFetch from '../../_common/_Hooks/useFetch'
import useForm from '../../_common/_Hooks/useForm'
import useTranslate from '../../_common/_Hooks/useTranslate'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Dashboard() {

    const { t, lang } = useTranslate()
    const form = useForm()
    const department = useSelector(s => s.department) || {}
    const user = useSelector(s => s.user) || {}

    // *********************************************************************************************************************
    const { res: instagram_feeds_res } = useFetch({
        endpoint: 'instagram/listInstagramFeeds',
        method: 'post',
        type: 'raw',
        selfCall: { isDashboard: true }
    })
    // *********************************************************************************************************************
    const { req: getDetails, res: details_res } = useFetch({
        endpoint: 'dashboard/latestDashboardApi',
        method: 'post',
        type: 'raw',
        selfCall: true,
        dependency: [department],
    })
    // *********************************************************************************************************************
    const { req: getIncidentStatusCounts, res: incidentstatusCounts_res } = useFetch({
        endpoint: 'dashboard/requestStatusCount',
        method: 'post',
        type: 'raw',
        selfCall: true,
        dependency: [department]
    })
    // *********************************************************************************************************************
    let instagram_feeds = instagram_feeds_res?.data
    let details = details_res?.data
    let incidentstatusCounts = incidentstatusCounts_res?.data
    let agentCallRequests = details?.avgCallRequestByAgent

    let has_incident_details = user.role === 'viewer' ? user?.viewer_permission?.includes('incident_details') : true
    let has_view_instagram_feeds = user.role === 'viewer' ? user?.viewer_permission?.includes('view_instagram_feeds') : true
    let has_agents_details = user.role === 'viewer' ? user?.viewer_permission?.includes('agents_details') : true
    let has_call_details = user.role === 'viewer' ? user?.viewer_permission?.includes('call_details') : true
    let has_manage_lecture_request = user.role === 'viewer' ? user?.viewer_permission?.includes('manage_lecture_request') : true
    let has_manage_volunteer_with_us = user.role === 'viewer' ? user?.viewer_permission?.includes('manage_volunteer_with_us') : true
    let has_is_english = user.role === 'viewer' ? user?.viewer_permission?.includes('is_english') : true

    let wise_departments = details?.deptwiseRequest.reduce((a, c) => ({ count: [...a.count, c.count], ar: [...a.ar, c.department_name_ar], en: [...a.en, c.department_name_en], key: [...a.key, c.id] }), { count: [], ar: [], en: [], key: [] })
    let wise_incidentTypes = details?.incidentTypeWiseRequest.reduce((a, c) => ({ count: [...a.count, c.count], ar: [...a.ar, c.incident_type_ar], en: [...a.en, c.incident_type_en], key: [...a.key, c.id] }), { count: [], ar: [], en: [], key: [] })
    let wise_sources = details?.sourceWiseRequest.reduce((a, c) => ({ count: [...a.count, c.count], label: [...a.label, c._id.requestStatus], key: [...a.key, c._id.handle_key] }), { count: [], label: [], key: [] })
    let statuses_lecture = details?.lectureRequest.reduce((a, c) => ({ count: [...a.count, c.count], status: [...a.status, c.request_status] }), { count: [], status: [] })
    let statuses_incident = incidentstatusCounts?.reduce((a, c) => ({ count: [...a.count, c.count], status: [...a.status, c.request_status] }), { count: [], status: [] })
    // *********************************************************************************************************************

    function filterHandler(data) {
        getDetails({
            startDate: data?.startDate,
            endDate: data?.endDate,
        })
        getIncidentStatusCounts({
            startDate: data?.startDate,
            endDate: data?.endDate,
        })
    }

    function chartfilterHandler(data) {
        console.log({ data, form: form.inputs })
        getIncidentStatusCounts({
            startDate: form.inputs?.startDate,
            endDate: form.inputs?.endDate,
            request_type: data?.request_type,
            incidentType: data?.incidentType,
            department_id: data?.department_id
        })
    }

    const calldetails = [
        { title: 'Total Calls', count: details?.callStatusResult?.totalCount, icon: '/assets/icons/dash3.svg' },
        { title: 'Answered Calls', count: details?.callStatusResult?.answeredCount, icon: '/assets/icons/callAnswered.svg' },
        { title: 'Busy Calls', count: details?.callStatusResult?.busyCount, icon: '/assets/icons/callBusy.svg' },
        { title: 'Cancelled Calls', count: details?.callStatusResult?.cancelCount, icon: '/assets/icons/callCancelled.svg' },
        { title: 'Average Duration', count: Number(details?.callStatusResult?.totalAvgCallDuration || 0).toFixed(2), icon: '/assets/icons/duration.svg' },
        { title: 'Response Time', count: Number(details?.callStatusResult?.totalResponseTime || 0).toFixed(2), icon: '/assets/icons/response-time.svg' },
    ]

    return (
        <Page className='Dashboard'>

            <Page.Header>
                <Title />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                <Filter form={form} onFilter={filterHandler} />
                <Counts details={details} viewerPermissions={{ has_call_details, has_incident_details }} />
                {has_call_details && <h3 className='agents'>{t("Call Details")}</h3>}
                {has_call_details && <Calls data={calldetails} />}
                {details?.avgCallRequestByAgent.length > 0 && <h3 className='agents'>{t("Agents")}</h3>}
                {details?.avgCallRequestByAgent.length > 0 && <Agents data={details?.avgCallRequestByAgent} />}
                {has_incident_details && <h3 className='incident'>{t("Incident Requests")}</h3>}
                {has_incident_details && <div className='circles'>
                    <ChartPie key={department?._id + 1} counts={wise_departments?.count} labels={wise_departments?.[lang === 'en' ? 'en' : 'ar']} keys={wise_departments?.key} onClick={chartfilterHandler} />
                    <ChartPolar key={department?._id + 2} counts={wise_sources?.count} labels={wise_sources?.label} keys={wise_sources?.key} onClick={chartfilterHandler} />
                    <ChartDoughnut key={department?._id + 3} counts={wise_incidentTypes?.count} labels={wise_incidentTypes?.[lang === 'en' ? 'en' : 'ar']} keys={wise_incidentTypes?.key} onClick={chartfilterHandler} />
                </div>}
                <div className='bars'>
                    {has_incident_details && <ChartBarV key={department?._id + 1} counts={statuses_incident?.count} labels={statuses_incident?.status} />}
                    {user.manage_lecture_request && <ChartBarH key={department._id + 2} counts={statuses_lecture?.count} labels={statuses_lecture?.status} />}
                </div>
                {instagram_feeds?.length > 0 && <InstagramFeeds data={instagram_feeds} />}
            </Page.Body>

        </Page>
    )
}