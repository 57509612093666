import React from 'react'
import { useSelector } from 'react-redux'
import { IMG_BASE_URL } from '../../config'
import AudioPlayer from 'react-h5-audio-player';
import useFetch from '../../_common/_Hooks/useFetch';
import LabelValue from '../../_common/_components/LabelValue/LabelValue';

export default function VoicePlayer({ file, requestId, type }) {

    const user = useSelector(s => s.user)

    // *********************************************************************************************************************
    const { req: downloadFile } = useFetch({
        endpoint: 'common/downloadFiles',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************

    function downloader(url, name) {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url
                link.download = name
                link.click();
            })
        downloadFile({
            requestId,
            type,
            filePath: url,
        })
    }

    return (
        <div id='VoicePlayer'>
            {file && <LabelValue label='Voice Mail' mt={20}>
                <div className='audio_player_wrap'>
                    <AudioPlayer
                        className={user.download_files ? 'download' : ''}
                        style={{ width: 500 }}
                        showJumpControls={false}
                        showFilledProgress={false}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        layout={'horizontal'}
                        src={`${IMG_BASE_URL}request/${file}`}
                    />
                    {user.download_files && <img className='download_icon' src="/assets/icons/dowld.svg" alt=""
                        onClick={() => downloader(`${IMG_BASE_URL}/request/${file}`, file)} />}
                </div>
            </LabelValue>}
        </div>
    )
}