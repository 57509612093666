import React from 'react'
import './Login.scss'
import useFetch from '../../_common/_Hooks/useFetch'
import { useNavigate } from 'react-router-dom'
import { setWaterMarkText } from '../../_common'
import Logs from '../../_common/_components/Logs/Logs'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import { v4 } from 'uuid'

export default function Login() {

    const navigate = useNavigate()
    const uuid = v4()

    // *********************************************************************************************************************
    const { req: login, lod: loader } = useFetch({
        name: 'user',
        endpoint: 'login/userLogin',
        method: 'post',
        type: 'raw',
        toast: true,
    })
    // *********************************************************************************************************************

    async function loginHandler(e) {
        login(
            {
                employee_id: e.username,
                password: e.password
            },
            (res) => {
                if (res.status) {
                    localStorage.setItem('token', res.data.token.accessToken)
                    localStorage.setItem('csrf', res.data.token.csrfToken)
                    localStorage.setItem('refreshToken', res.data.token.refreshToken)
                    setWaterMarkText(res.data.user.employee_id)
                    navigate('/')
                }
            }
        )
    }

    return (
        <div id='Login'>
            <Logs>
                <img className='logo' src="/assets/al_ameen_logo.svg" alt="" />
                <Logs.Username labelGap={10} mb={20} mt={40} />
                <Logs.Password labelGap={10} mb={60} />
                <Logs.Submit icon='/assets/icons/login_btn_icon.svg' reverse iconWidth={30} onClick={loginHandler} loader={loader} hashKey={uuid} />
                <p className='forgot_pwd' onClick={() => Modals.ForgotPassword()}>Forgot Password ?</p>
            </Logs>
            <video src="/assets/login_video.mp4" muted loop autoPlay></video>
        </div>
    )
}