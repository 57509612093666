import React from 'react'
import './Image.scss'
import { classMaker } from '../..'
import config from '../../../common/config'
import icon_dummy from './Img_dmy.jpg'

export default function Image({ className, css, src, label, alt, onClick, width }) {
    return (
        <span id='Image' className={classMaker(className, css, 'Image')} onClick={() => onClick && onClick()}>
            {label && <p>{label}</p>}
            <img width={width || 35} src={src} alt="" onError={e => e.target.src = alt || config.Image_alt || icon_dummy} />
        </span>
    )
}