import React, { Children, useContext, useEffect, useRef, useState } from 'react'
import './Slider.scss'
import icon_arrow from './sld_arrow.svg'
import { classMaker } from '../..'
import { AppContext } from '../../App'

export default function Slider({ className, css, type, children, initial }) {

    const { dir } = useContext(AppContext)

    const ref = useRef()

    const [selected, setSelected] = useState(0)

    useEffect(() => {
        setSelected(initial || 0)
    }, [initial])



    useEffect(() => {
        let videoTags = ref.current.querySelectorAll('video')
        videoTags.forEach(tag => {
            tag.addEventListener('play', (e) => playNpauseHandler(e, true));
            tag.addEventListener('pause', (e) => playNpauseHandler(e));
        })
    }, [ref.current])

    function playNpauseHandler(e, isPlay) {
        if (isPlay) {
            e.target.parentElement.parentElement.classList.add('video_playing')
        } else {
            e.target.parentElement.parentElement.classList.remove('video_playing')
        }
    }

    function navHandler(value) {
        let end = Children.count(children) - 1
        setSelected(s => {
            if ((value === 1 && s === end) || (value === -1 && s === 0))
                return s
            return s + value
        })
    }

    function activityChecker(index) {
        return index === selected
            ? 'active'
            : index === selected - 1
                ? 'active_1'
                : index === selected + 1
                    ? 'active__1'
                    : ''
    }

    return (
        <div id='Slider' className={classMaker(className, css, 'Slider') + (type ? (' type_' + type) : '')}>
            {selected !== 0 && <img className='arrow left' src={icon_arrow} alt="" onClick={() => navHandler(-1)} />}
            <div className="content" ref={ref}>
                {
                    Children.map(children, (item, i) => {

                        return (
                            <div key={i} className={'slider_item ' + activityChecker(i)}>
                                {item}
                            </div>
                        )
                    })
                }
            </div>
            {selected !== (React.Children.count(children) - 1) && <img className='arrow right' src={icon_arrow} alt="" onClick={() => navHandler(1)} />}
        </div>
    )
}