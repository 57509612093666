import React from 'react'
import './Card.scss'
import { classMaker } from '../..'


export default function Card({ className, title, subTitle, titleIcon, children, css }) {

  return (
    <div id='Card' className={classMaker(className, css, 'Card')}>
      <div className="card__head">
        {titleIcon && <img src={titleIcon} />}
        <span className='titles'>
          {title && <h4>{title}</h4>}
          {subTitle && <p>{subTitle}</p>}
        </span>
      </div>
      <div className='card__body'>
        {children}
      </div>
    </div>
  )
}
