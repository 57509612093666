import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Files.scss'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { classMaker } from '../..'

import closeIcon from './ac_close.svg'
import docIcon from './ac_doc.svg'
import docxIcon from './ac_docx.svg'
import mp4Icon from './ac_mp4.svg'
import movIcon from './ac_mov.svg'
import pdfIcon from './ac_pdf.svg'
import xlsxIcon from './ac_xlsx.svg'
import xlsIcon from './ac_xls.svg'
import csvIcon from './ac_csv.svg'
import pptxIcon from './ac_pptx.svg'
import pptIcon from './ac_ppt.svg'
import avifIcon from './ac_avif.svg'
import svgIcon from './ac_svg.svg'
import zipIcon from './ac_zip.svg'
import mp3Icon from './ac_mp3.svg'
import wavIcon from './ac_wav.svg'
import errorFile from './ac_errorFile.svg'
import dummy from './ac_dummy.jpg'
import useTranslate from '../../_Hooks/useTranslate'

let icons = {
  png: 'file',
  jpg: 'file',
  jpeg: 'file',
  png: 'file',
  webp: 'file',
  pdf: pdfIcon,
  doc: docIcon,
  docx: docxIcon,
  mov: movIcon,
  mp4: mp4Icon,
  xlsx: xlsxIcon,
  csv: csvIcon,
  ppt: pptIcon,
  pptx: pptxIcon,
  xls: xlsIcon,
  avif: avifIcon,
  svg: svgIcon,
  zip: zipIcon,
  wav: wavIcon,
  mp3: mp3Icon
}

function fileParser(file) {
  let type = file.type?.toLowerCase() || file.url?.split('.').pop().toLowerCase()
  let src = icons[type]
  if (src === 'file') src = file.url
  if (file.isInvalid) src = errorFile
  let isImage = icons[type] === 'file' && !file.isInvalid
  let isVideo = ['mp4', 'mov'].includes(type)
  let isPdf = type === 'pdf'
  let isAudio = ['wav', 'mp3'].includes(type)
  return { src, isImage, isVideo, isPdf, isAudio, file }
}

export default function Files({ className, css, value, closeButton, onClose, label, hideOnEmpty, onClick }) {

  const { t } = useTranslate()
  const [inFiles, setFiles] = useState([])

  useEffect(() => {
    value && setFiles(value)
    return () => setFiles([])
  }, [value])

  function fileCloseHandler(fileToDelete) {
    let result = inFiles.filter(file => file !== fileToDelete)
    onClose && onClose(result.map(item => item))
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} style={{ fontSize: '12px' }}>
      {props.label}
    </Tooltip>
  );

  if (hideOnEmpty && inFiles.length === 0) return null

  return (
    <div id='Files' className={classMaker(className, css, 'Files')} >
      {label && <label htmlFor="">{t(label)}</label>}
      <span className='files_content'>
        {inFiles.length > 0
          ? inFiles.map((file, i) => {
            let { src, isImage } = fileParser(file)
            return (
              <span key={i} className={isImage ? 'image' : ''}>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip({ label: file.name })} >
                  {
                    onClick
                      ? <img className='file pre' src={src || dummy} onError={e => e.target.src = dummy} onClick={() => onClick && onClick(file)} />
                      : <a href={file.url} target='_blank' >
                        <img className='file' src={src || dummy} onError={e => e.target.src = dummy} />
                      </a>
                  }
                </OverlayTrigger>
                {closeButton && <img onClick={() => fileCloseHandler(file)} className='close' src={closeIcon} alt="" />}
              </span>
            )
          })
          : '--'
        }
      </span>
    </div >
  )
}

Files.Preview = ({ file, custom }) => {
  let { src, isImage, isAudio, isVideo, isPdf } = fileParser(file)
  return (
    <div id="Files_preview" >
      {
        isAudio ? (custom({ isAudio, file }) || <audio className='file-prev' src={file.url} controls controlsList='nodownload'></audio>)
          : isVideo ? (custom({ isVideo, file }) || <video className='file-prev' controls src={file.url}></video>)
            : isPdf ? (custom({ isPdf, file }) || <object className='file-prev' data={file.url + '#toolbar=0'} type=""></object>)
              : isImage ? (custom({ isImage, file }) || <img className='file-prev' src={file.url} />)
                : <img className='file-icon' src={src || dummy} onError={e => e.target.src = dummy} />
      }
    </div>
  )
}



{/* 

<Files
  files={[
    {
      type: 'pdf',
      url: '',
      name: ''
    }
  ]}
  onClose={() => { }}
  closeButton
]} />

*/}