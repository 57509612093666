import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import ActionButtons from '../../_common/_components/ActionButtons/ActionButtons'
import useFetch from '../../_common/_Hooks/useFetch'
import Flex from '../../_common/_components/Flex/Flex'
import Table from '../../_common/_components/Table/Table'
import useFilter from '../../_common/_Hooks/useFilter'
import Select from '../../_common/_components/Select/Select'
import Button from '../../_common/_components/Button/Button'
import Input from '../../_common/_components/Input/Input'
import DatePick from '../../_common/_components/DatePick/DatePick'
import TextArea from '../../_common/_components/TextArea/TextArea'
import useForm from '../../_common/_Hooks/useForm'
import { useNavigate, useParams } from 'react-router-dom'

export default function Event_add() {

    const { id } = useParams()
    const navigate = useNavigate()
    const { formPlug, inputs, validate, setInputs, inputHandler } = useForm()

    // *********************************************************************************************************************
    useFetch({
        endpoint: 'event/getEventDetail',
        method: 'get',
        type: 'params',
        selfCall: id || false,
        success: (res) => {
            setInputs({
                title: res.data.title,
                event_from: new Date(res.data.event_from),
                event_to: new Date(res.data.event_to),
                description: res.data.description,
            })
        }
    })
    // *********************************************************************************************************************
    const { req: submit } = useFetch({
        endpoint: 'event/addEvent',
        method: 'post',
        type: 'raw',
        toast: true,
    })
    // *********************************************************************************************************************
    const { req: edit } = useFetch({
        endpoint: 'event/editEvent',
        method: 'put',
        type: 'raw',
        toast: true,
    })
    // *********************************************************************************************************************

    async function submitHandler() {
        if (!await validate()) return
        let obj = {
            id,
            title: inputs.title,
            event_from: inputs.event_from,
            event_to: inputs.event_to,
            description: inputs.description,
        }
        if (id) edit(obj, () => { navigate('/volunteer/events') })
        else submit(obj, () => { navigate('/volunteer/events') })
    }

    return (
        <Page className='Event_detail'>

            <Page.Header>
                <Title />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title={'Details'}>
                    <Input
                        css={1}
                        mb={20}
                        label='Title'
                        field='title'
                        inputSocket={formPlug}
                        required
                    />
                    <Flex columns={2} width={800} mb={10} gap={10}>
                        <DatePick
                            css={1}
                            label='From Date'
                            field='event_from'
                            inputSocket={formPlug}
                            onChange={(e, k) => {
                                setInputs(s => ({ ...s, event_to: '' }))
                                inputHandler(e, k)
                            }}
                            timeSelect
                            required
                        // maxDate={inputs.event_to}
                        />
                        <DatePick
                            css={1}
                            label='To Date'
                            field='event_to'
                            inputSocket={formPlug}
                            timeSelect
                            required
                            minDate={inputs.event_from}
                            minTime={new Date(inputs.event_to).setHours(0, 0, 0) === new Date(inputs.event_from).setHours(0, 0, 0) ? inputs.event_from : new Date().setHours(0, 0, 0)}
                            maxTime={new Date().setHours(23, 59, 59)}
                        />
                    </Flex>
                    <TextArea
                        css={2}
                        label='Description'
                        field='description'
                        inputSocket={formPlug}
                        required
                    />
                </TitleBar>
            </Page.Body>

            <ActionButtons _css={{ button: 1 }}>
                <Button css={1} content='Cancel' onClick={() => navigate(-1)} />
                <Button css={1} content='Submit' onClick={submitHandler} />
            </ActionButtons>

        </Page>
    )
}
