import React from 'react'
import './PopupContent.scss'
import icon_close from './close1.svg'
import { classMaker } from '../..'

export default function PopupContent({ className, css, children, handler, hideCloseBtn, width }) {

    return (
        <div id='PopupContent' className={classMaker(className, css, 'Modal')} style={{ width }}>
            {!hideCloseBtn && <img id='m_close_btn' src={icon_close} alt="" onClick={() => { handler('close') }} />}
            {children}
        </div>
    )
}