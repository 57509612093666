import React, { useState, useEffect, useRef, useContext } from 'react'
import './FileUpload.scss'
import Files from '../Files/Files'
import { v4 } from 'uuid'
import { Spinner } from 'react-bootstrap'
import uploadIcon from './upload.svg'
import useTranslate from '../../_Hooks/useTranslate'
import ProgressBar from '../ProgressBar/ProgressBar'
import { classMaker } from '../..'
import { ModalContentContext } from '../ModalContent/ModalContent'

const FileUploadContext = React.createContext()
export default function FileUpload({ className, css, reverse, value, onChange, error, loader, maxFileSizeMB, showPreview, inputSocket, field, info, multiple, required, label = 'label', buttonLabel, accept, width, style = {}, condition, clearOnHide, icon, dragNdrop, moreText, hideStar, progress, mb, mt, mr, ml, limited, height, validFilesOnly, FUref }) {

  const { formPlug } = useContext(ModalContentContext) || {}
  inputSocket = inputSocket || formPlug

  let connector = inputSocket?.connector
  error = error || inputSocket?.errors
  value = value || inputSocket?.inputs
  onChange = onChange || inputSocket?.inputHandler

  let acceptMessage = accept?.message
  accept = Array.isArray(accept) ? accept : accept?.items

  let maxFileSizeMessage = maxFileSizeMB?.message
  maxFileSizeMB = typeof maxFileSizeMB === 'number' ? maxFileSizeMB : maxFileSizeMB?.value

  const inputRef = useRef()

  let uuid = v4()
  const { t } = useTranslate()

  const [input, setInput] = useState([])
  const [isInvalidFile, setIsInvalidFile] = useState(false)
  const [inError, setInError] = useState('')

  useEffect(() => {
    setInput(() => {
      let res = []
      if (typeof value === 'object' && !Array.isArray(value) && value?.[field] !== undefined) res = value?.[field]
      else if (typeof value === 'object' && !Array.isArray(value) && value?.[field] === undefined) res = []
      else if (value !== undefined) res = value
      return res
    })
    if (!field) setInError('Please add field')
    return () => setInput([])
  }, [value])

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field, default: [] }
      if (required) obj.required = required
      connector && connector(obj)
      if (Object.keys(obj).length > 2 && !connector) setInError("Please connect 'formPlug' to 'inputSocket'")
    }
    return () => { connector && connector({ key: field, clearValidation: true, default: [] }) }
  }, [...(required && typeof required === 'object' ? Object.values(required) : [required]), condition])

  useEffect(() => {
    return () => {
      condition && clearOnHide && connector && connector({ key: field, clearValidation: true, clearValue: true, default: [] })
    }
  }, [condition])

  function fileSizeChecker(files, cb) {
    let MBdivisor = 1048576
    let maxSize = maxFileSizeMB || 0 / MBdivisor
    let filesSize = [...files].reduce((acc, crr) => acc + crr.size, 0) / MBdivisor
    if (filesSize > maxSize) cb()
  }

  function changeHandler(e) {
    let candidates = []
    if (multiple) candidates = [...input, ...fileObjectSetter(e)]
    else candidates = fileObjectSetter(e)
    let [isInvalid, message] = validation(candidates)
    setIsInvalidFile(isInvalid)
    if (validFilesOnly && isInvalid) message && inputSocket?.setErrors(s => ({ [field]: message }))
    if (validFilesOnly ? !isInvalid : true) setInput(candidates)
    if (validFilesOnly ? !isInvalid : true) onChange && onChange(candidates, field, { isFileUpload: true, id: uuid, isInvalid, message })
  }

  function closeHandler(e) {
    let [isInvalid, message] = validation(e)
    setInput(e)
    onChange && onChange(e, field, { isFileUpload: true, id: uuid, isInvalid, message, isClosing: true })
  }

  FUref?.current && (FUref.current = closeHandler)

  function fileObjectSetter(e) {
    let draggedFiles = e.dataTransfer?.files
    let eventfiles = draggedFiles ? (multiple ? draggedFiles : [draggedFiles[0]]) : e.target.files
    return Array.from(eventfiles).map(item => {
      let type = fileParser(item.type)
      return {
        name: item.name,
        url: URL.createObjectURL(item),
        type: type,
        metaFile: item,
        isInvalid: !accept?.includes(type),
        size: item.size
      }
    })
  }

  function validation(candidates) {
    let isInvalid = false
    let message = ''
    maxFileSizeMB && fileSizeChecker(candidates, () => {
      isInvalid = true
      message = maxFileSizeMessage || `File size shouldn't exceed ${maxFileSizeMB} MB`
    })
    if (candidates.some(item => item.isInvalid)) {
      isInvalid = true
      message = acceptMessage || 'Invalid file format'
    }
    return [isInvalid, message]
  }

  function dragHandler(e) {
    if (dragNdrop) e.preventDefault()
  }

  function dropHandler(e) {
    if (dragNdrop) e.preventDefault()
    changeHandler(e)
  }

  function fileParser(type) {
    switch (type) {
      case 'application/pdf':
      case 'pdf': return 'pdf'
      case 'application/msword':
      case 'doc': return 'doc'
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'docx': return 'docx'
      case 'video/quicktime':
      case 'mov': return 'mov'
      case 'video/mp4':
      case 'mp4': return 'mp4'
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'xlsx': return 'xlsx'
      case 'image/jpg':
      case 'jpg': return 'jpg'
      case 'image/jpeg':
      case 'jpeg': return 'jpeg'
      case 'image/png':
      case 'png': return 'png'
      case 'image/avif':
      case 'avif': return 'avif'
      case 'image/svg+xml':
      case 'svg': return 'svg'
      case 'image': return 'image'
      case 'text/csv':
      case 'csv': return 'csv'
      case 'application/vnd.ms-powerpoint':
      case 'ppt': return 'ppt'
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'pptx': return 'pptx'
      case 'application/vnd.ms-excel':
      case 'xls': return 'xls'
      case 'audio/wav': return 'wav'
      case 'audio/mpeg': return 'mp3'
      default: return ''
    }
  }

  if (!condition && condition !== undefined) return null

  const fileUploadStyle = { width: width, height: height, marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml, ...style }

  const providerValues = {
    label,
    required,
    hideStar,
    info,
    showPreview,
    closeHandler,
    progress,
    inError,
    error,
    field,
    input,
    isInvalidFile,
    inputRef,
    multiple,
    changeHandler,
    accept,
    loader,
    buttonLabel,
    reverse,
    uuid,
    icon,
    moreText,
    limited,
    setInput
  }

  if (limited > 0) return (
    <div id='FileUpload' className={classMaker(className, css, 'FileUpload') + ' limited'} style={fileUploadStyle}>
      <FileUploadContext.Provider value={providerValues}>
        <Label />
        <FileInput multiple={false} />
        <LimitedButtons />
        <Error />
        <Progress />
      </FileUploadContext.Provider>
    </div>
  )
  else if (dragNdrop) return (
    <div id='FileUpload' className={classMaker(className, css, 'FileUpload') + ' drag_drop'} style={fileUploadStyle}>
      <FileUploadContext.Provider value={providerValues}>
        <Label />
        <FileInput />
        <Button
          buttonLabel={moreText}
          onDrop={dropHandler}
          onDragStart={dragHandler}
          onDragLeave={dragHandler}
          onDragOver={dragHandler}
        />
        <Error />
        <Progress />
        <ShowPreview />
      </FileUploadContext.Provider>
    </div>
  )
  else return (
    <div id='FileUpload' className={classMaker(className, css, 'FileUpload') + ' button'} style={fileUploadStyle}>
      <FileUploadContext.Provider value={providerValues}>
        <Label />
        <FileInput />
        <Button />
        <Error />
        <Progress />
        <ShowPreview />
      </FileUploadContext.Provider>
    </div>
  )
  // else return (
  //   <div id='FileUpload' className={classMaker(className, css, 'FileUpload')} style={fileUploadStyle} >
  //     <span className='_fileupload'>
  //       {label && <p className='label'>{t(label)}{(required?.condition !== undefined ? required?.condition : required) && !hideStar && <em>*</em>}{info}</p>}
  //       <span className={'__fileupload' + (dragNdrop ? ' drag_drop' : '') + (limited > 0 ? ' lim' : '')} onClick={() => dragNdrop && inputRef.current?.click()} onDrop={dropHandler} onDragStart={dragHandler} onDragLeave={dragHandler} onDragOver={dragHandler}>
  //         {moreText
  //           ? <p className='dd_label'>
  //             {moreText}
  //             {icon && moreText && <img src={icon} alt="" />}
  //           </p>
  //           : <label htmlFor={'attach_' + uuid} className={reverse ? 'reverse' : ''}>
  //             {t(buttonLabel || 'Upload file')}
  //             {loader
  //               ? <Spinner animation="border" role="status" size="sm"></Spinner>
  //               : icon && !moreText && <img className='upload_icon' src={icon || uploadIcon} />
  //             }
  //           </label>
  //         }
  //         <input ref={inputRef} type="file" id={'attach_' + uuid} hidden multiple={multiple} onClick={(e) => e.target.value = null} onChange={changeHandler}
  //           accept={accept?.map(item => '.' + item)} disabled={loader} />
  //         <a className={'file_info' + (input[0] && !isInvalidFile ? ' file' : '')} target='_blank' href={input.length !== 0 ? input[0]?.url : ''}>
  //           {input[0]?.name ? (isInvalidFile ? t('Invalid file') : input[0].name) : t('No file chosen')}
  //         </a>
  //       </span>
  //       {(inError || (typeof error === 'object' ? error?.[field] : error)) && <small id='error' className='error'>{inError || (typeof error === 'object' ? t(error?.[field] || '') : t(error))}</small>}
  //     </span>
  //     {showPreview && !loader && !progress && input.length > 0 && <Files value={input} closeButton onClose={closeHandler} accept={accept} />}
  //     {progress && !loader && <ProgressBar progress={progress} />}

  //   </div>
  // )
}
FileUpload.displayName = 'FileUpload'

// **************************************************************************************************

const Label = () => {
  const { label, required, hideStar, info } = useContext(FileUploadContext)
  const { t } = useTranslate()
  if (label)
    return (
      <p className='label'>{t(label)}{(required?.condition !== undefined ? required?.condition : required) && !hideStar && <em>*</em>}{info}</p>
    )
}

// **************************************************************************************************

const ShowPreview = () => {
  const { showPreview, loader, progress, input, closeHandler, accept } = useContext(FileUploadContext)
  if (showPreview && !loader && !progress && input.length > 0)
    return <Files value={input} closeButton onClose={closeHandler} accept={accept} />
}

// **************************************************************************************************

const Progress = () => {
  const { progress, loader } = useContext(FileUploadContext)
  if (progress && !loader)
    return <ProgressBar progress={progress} />
}

// **************************************************************************************************

const Error = () => {
  const { inError, error, field } = useContext(FileUploadContext)
  const { t } = useTranslate()
  if (inError || (typeof error === 'object' ? error?.[field] : error))
    return <small id='error' className='error'>{inError || (typeof error === 'object' ? t(error?.[field] || '') : t(error))}</small>
}

// **************************************************************************************************

const ChooseFile_Inset = () => {
  const { input, isInvalidFile } = useContext(FileUploadContext)
  const { t } = useTranslate()
  return (
    <a className={'file_info' + (input[0] && !isInvalidFile ? ' file' : '')} target='_blank' href={input.length !== 0 ? input[0]?.url : ''}>
      {input[0]?.name ? (isInvalidFile ? t('Invalid file') : input[0].name) : t('No file chosen')}
    </a>
  )
}

// **************************************************************************************************

const FileInput = ({ multiple }) => {
  const { inputRef, uuid, changeHandler, accept, loader, ...rest } = useContext(FileUploadContext)
  multiple = multiple === undefined ? rest.multiple : multiple
  const { t } = useTranslate()
  return (
    <input
      ref={inputRef} type="file" id={'attach_' + uuid} hidden multiple={multiple} onClick={(e) => e.target.value = null}
      onChange={changeHandler} accept={accept?.map(item => '.' + item)} disabled={loader} />
  )
}

// **************************************************************************************************

const Button = ({ className, buttonLabel, onDrop, onDragStart, onDragLeave, onDragOver }) => {
  const { loader, reverse, uuid, icon, ...rest } = useContext(FileUploadContext)
  buttonLabel = buttonLabel !== undefined ? buttonLabel : rest.buttonLabel
  const { t } = useTranslate()
  return (
    <label htmlFor={'attach_' + uuid} className={className + (reverse ? ' reverse' : '')}
      onDrop={onDrop} onDragStart={onDragStart} onDragLeave={onDragLeave} onDragOver={onDragOver}
    >
      {t(buttonLabel)}
      {loader
        ? <Spinner animation="border" role="status" size="sm"></Spinner>
        : icon && <img className='icon' src={icon || uploadIcon} />
      }
    </label>
  )
}

// **************************************************************************************************

const LimitedButtons = () => {
  const { limited, input } = useContext(FileUploadContext)
  if (limited > 0) return (
    <div className='lim_btns'>
      <ShowPreview />
      {
        [...Array(limited - input.length)].map((a, i) => {
          return <Button key={i} className='lim_item' />
        })
      }
    </div>
  )
}

// **************************************************************************************************