import React, { useState, useEffect } from 'react'
import './Table.scss'
import { useRef } from 'react'
import useTranslate from '../../../_common/_Hooks/useTranslate'
import Pagination from '../Pagination/Pagination'
import { classMaker } from '../..'
import EntriesCounter from '../EntriesCounter/EntriesCounter'
import Input from '../Input/Input'
import icon_search from './search.svg'
import icon_arrow from './tbl_toggle_arrow.svg'

export default function Table({ className, css, _css, titles = [{ title: '', width: '', key: '', last: '', condition: '' }], boxwidthReduce, content = [], pageDetails = { page: '', pages: '', status: '' }, tbody, scroll_Max_Rows, filterData, filterHandler, onRowClick, moreFilters, hideFilters, additionalDetails, dependency }) {

  const [inTitles, setTitles] = useState([])
  const [data, setData] = useState([])
  const [sort, setSort] = useState({})
  const [tableHeight, setTableHeight] = useState(null)
  const [fittableCount, setFittableCount] = useState(0)
  const [fc, setFc] = useState()
  const [fch, setFch] = useState()
  const [items, setItems] = useState()

  const { t } = useTranslate()
  const tableRef = useRef()
  const divRef = useRef()

  useEffect(() => {

    if (fittableCount === 0 && false) {
      let total = 0
      let fittable = 0
      Array.from(tableRef.current.rows[0]?.childNodes || [])?.map(title => {
        if ((total += (title.clientWidth - .5)) <= (divRef.current.clientWidth - (boxwidthReduce || 0))) { fittable++ }
      })
      setFittableCount(fittable)
    }


    let nonFittableCount = titles.length - fittableCount
    let items = []

    if (nonFittableCount < titles.length && false) {
      let arr = []
      let nonLasts = []
      inTitles.map((column, col_index) => {
        if (!column?.condition && column?.condition !== undefined) return null
        if (column.last && column.last <= nonFittableCount) {
          arr[column.last] = column
        } else {
          nonLasts.push(column)
        }
      })
      items = [...nonLasts, ...arr.reverse().filter(a => a !== undefined)]
    } else {
      items = inTitles
    }

    setFch(<HeadRow
      fittableCount={fittableCount}
      titles={items}
      sort={sort}
      scroll_Max_Rows={scroll_Max_Rows}
      sortHandler={sortHandler}
    />)

    setFc(data.map((rowData, index) => {
      return <BodyRow
        fittableCount={fittableCount}
        data={rowData}
        index={index}
        key={index}
        titles={items}
        nonFittableCount={nonFittableCount}
        onRowClick={onRowClick}
        sort={sort}
        sortHandler={sortHandler}
        additionalDetails={additionalDetails}
      />
    }))

  }, [divRef.current, tableRef.current, data, titles, inTitles])

  useEffect(() => {
    titles && setTitles(titles)
    content && setData(content)
    return () => {
      setTitles([])
      setData([])
    }
  }, [titles])

  useEffect(() => {
    if (scroll_Max_Rows) {
      if (data?.length > scroll_Max_Rows) {
        setTimeout(() => {
          let bodyRowHeight = tableRef?.current?.querySelector('tbody')?.querySelector('tr')?.clientHeight
          let headRowHeight = tableRef?.current?.querySelector('thead')?.querySelector('tr')?.clientHeight
          let netHeight
          if (bodyRowHeight) netHeight = bodyRowHeight * scroll_Max_Rows
          if (headRowHeight) netHeight += headRowHeight
          if (netHeight) setTableHeight(netHeight)
        }, 0)
      } else setTableHeight('auto')
    }
  }, [scroll_Max_Rows, data])

  let sortColumn = filterData?.sort_key
  let sortValue = filterData?.sort_order

  useEffect(() => {
    if (sortColumn && sortValue) {
      setSort({ [sortColumn]: sortValue === 1 })
    }
  }, [sortColumn, sortValue])

  function sortHandler(key) {
    let value = key in sort ? !sort[key] : true
    setSort({ [key]: value })
    filterHandler && filterHandler('sort', { key: key, value: value ? 1 : -1 })
  }

  function pageChangeHandler(e) {
    filterHandler && filterHandler('page', e)
  }

  return (
    <div id='Table' className={classMaker(className, css, 'Table')} style={{ height: tableHeight, paddingTop: scroll_Max_Rows ? 0 : '' }}>
      <section className='table_handler'>
        {(hideFilters === undefined || !hideFilters) &&
          <>
            <EntriesCounter css={_css?.entries} value={filterData?.perPage || 0} onChange={(e) => { filterHandler && filterHandler('entries', e) }} />
            <Input
              css={_css?.search}
              value={filterData?.keyword}
              icon={icon_search}
              placeholder={'Search here'}
              width="300px"
              field="keyword"
              showCloseBtn
              onChange={e => !e && filterHandler && filterHandler('keyword', e)}
              onSubmit={e => filterHandler && filterHandler('keyword', e)}
              onIconClick={e => filterHandler && filterHandler('keyword', e)}
              reverse
              dependency={dependency}
            />
          </>
        }
        {moreFilters}
      </section>
      <div className='table_content' ref={divRef}>
        <table ref={tableRef}>
          <thead>
            {fch}
          </thead>
          <tbody>
            {tbody ? tbody : fc}
          </tbody>
        </table>
        {
          (Array.isArray(tbody) ? tbody.length === 0 : !tbody && data.length === 0) && inTitles.length !== 0
          && <p className='no__data'>{t("No Data Available")}</p>
        }
      </div>
      <Pagination css={_css?.pagination} page={pageDetails.page} pageCount={pageDetails.pages} pageStatus={pageDetails.status} onChange={pageChangeHandler} />
    </div >
  )
}


const BodyRow = ({ index, fittableCount, data, titles, nonFittableCount, onRowClick, sort, sortHandler, additionalDetails }) => {

  const [rowToggle, setRowToggle] = useState()
  const [detailsToggle, setDetailsToggle] = useState()

  let mores = []

  return (
    <React.Fragment key={index}>
      <tr key={index} onClick={() => onRowClick && onRowClick(data)} style={{ cursor: onRowClick ? 'pointer' : 'auto' }}>
        {
          titles?.map((column, col_index) => {
            if (!column?.condition && column?.condition !== undefined) return null
            if (fittableCount !== 0 && col_index + 1 > fittableCount) {
              mores.push(
                <span key={col_index} style={{ width: column.width }}>
                  <label >
                    <Sorter column={column} sort={sort} sortHandler={sortHandler} />
                    {column.title}
                  </label>
                  <span>{data[column.key] || '-'}</span>
                </span>
              ); return
            }
            return (
              <td
                className={column.key}
                key={col_index}
                dir={column.dir}
                style={{ textAlign: column.d_align, paddingRight: column.d_align === 'center' ? '0' : '' }}
                colSpan={column.span}
              >
                {
                  col_index === 0 &&
                  Boolean(nonFittableCount) &&
                  fittableCount !== 0 &&
                  <img className={'toggle resp' + (rowToggle === index ? ' on' : ' off')} src={icon_arrow} alt=''
                    onClick={(e) => { e.stopPropagation(); setRowToggle(s => (s !== undefined && s === index) ? null : index) }} />
                }
                {
                  col_index === 0 &&
                  data?.additionalDetails &&
                  <img className={'toggle additional' + (detailsToggle === index ? ' on' : ' off')} width={20} src={'/assets/icons/plus_black.svg'} alt=''
                    onClick={(e) => { e.stopPropagation(); setDetailsToggle(s => (s !== undefined && s === index) ? null : index) }} />
                }
                {data[column.key] || '-'}
              </td>
            )
          })
        }
      </tr>
      {mores.length > 0 &&
        <tr className={'other_fields' + (rowToggle === index ? ' toggle_on' : ' toggle_off')}>
          <td colSpan={100}>
            <div>
              {mores}
            </div>
          </td>
        </tr>}
      {
        data.additionalDetails &&
        <tr className={'additonal_details' + (detailsToggle === index ? ' toggle_on' : ' toggle_off')}>
          <td colSpan={100}>
            {data.additionalDetails}
          </td>
        </tr>
      }
    </React.Fragment>
  )
}

const HeadRow = ({ titles, fittableCount, scroll_Max_Rows, sort, sortHandler }) => {

  const { t } = useTranslate()
  return (
    <tr>
      {
        titles?.map((column, i) => {
          if (!column?.condition && column?.condition !== undefined) return null
          if (fittableCount && (i + 1) > fittableCount) return
          return (
            <th
              key={i}
              className={column.sort ? 'sort' : ''}
              width={column.width}
              style={{
                minWidth: column.minWidth || column.width,
                textAlign: column.h_align,
                paddingRight: column.h_align === 'center' ? '0' : '',
                position: scroll_Max_Rows ? 'sticky' : '',
              }}
            >
              {t(column.title)}
              <Sorter column={column} sort={sort} sortHandler={sortHandler} />
            </th>
          )
        })
      }
    </tr>
  )
}

const Sorter = ({ column, sort, sortHandler }) => {
  if (column.sort) return (
    <span
      className={'sorter' + (column.key in sort ? (sort?.[column.key] ? ' sort__asc' : ' sort__dsc') : '')}
      onClick={() => sortHandler(column.key)}>
    </span>
  )
}

{/*

<Table
  className=''
  titles={[
    { title: 'Sl No', key: 'sl_no', width: '', h_align: '', d_align: '' },
    { title: 'First Name', key: 'first_name', width: '', h_align: '', d_align: '' },
    { title: 'Last Name', key: 'last_name', width: '', h_align: '', d_align: '' },
  ]}
  content={[
    { sl_no: 1, first_name: 'user name', last_name: 'last name' },
    { sl_no: 2, first_name: 'first name', last_name: 'user name' }
  ]}
/>

*/}