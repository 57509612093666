import React, { useEffect, useRef } from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import DatePick from '../../_common/_components/DatePick/DatePick'
import TextArea from '../../_common/_components/TextArea/TextArea'
import FileUpload from '../../_common/_components/FileUpload/FileUpload'
import FooterButtons from '../../_common/_components/FooterButtons/FooterButtons'
import Button from '../../_common/_components/Button/Button'
import { useNavigate } from 'react-router'
import useFetch from '../../_common/_Hooks/useFetch'
import { IMG_BASE_URL } from '../../config'
import useTranslate from '../../_common/_Hooks/useTranslate'
import useForm from '../../_common/_Hooks/useForm'
import { useParams } from 'react-router-dom'
import Insta_Card from '../../components/Insta_Card/Insta_Card'
import Flex from '../../_common/_components/Flex/Flex'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import { useSelector } from 'react-redux'
import Image from '../../_common/_components/Image/Image'
import { Confirm_with_comment } from '../../common/Modals'
import useModal from '../../_common/_Hooks/useModal'

export default function AddFeed({ isEdit, isView }) {

    const navigate = useNavigate()
    const { _lang } = useTranslate()
    const furef = useRef()
    const m_Confirm = useModal(<Confirm_with_comment />)

    const { id } = useParams()

    const statuses = useSelector(s => s.statuses)
    const user = useSelector(s => s.user)

    // *********************************************************************************************************************
    const { req: add_edit_Feed } = useFetch({
        endpoint: 'instagram/addInstagramFeeds',
        method: 'post',
        type: 'raw',
        toast: true,
    })
    // *********************************************************************************************************************
    const { req: getDetails, res: details_res } = useFetch({
        endpoint: 'instagram/getInstagramPostDetail',
        method: 'get',
        type: 'params',
    })
    // *********************************************************************************************************************
    const { req: uploadFiles, lod: fileUploadLoader, pro: fileUploadProgress } = useFetch({
        endpoint: 'file/uploadFile',
        method: 'post',
        type: 'formdata',
        privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: instaFeedRequestAction } = useFetch({
        endpoint: 'instagram/approvalInstagramFeeds',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // *********************************************************************************************************************
    const details = details_res?.data
    // *********************************************************************************************************************

    const { inputs, setInputs, formPlug, validate, filer, setErrors } = useForm({}, { fileUploader: uploadFiles });

    useEffect(() => {
        (isEdit || isView) && getDetails(id, (res) => {
            const res_data = res.data
            setInputs({
                toBePosted: new Date(res_data.toBePosted),
                descripition: res_data.descripition,
                ...filer('instagram_post', { base: IMG_BASE_URL, folder: 'profile', ends: res_data.instagram_post }),
            })
        })
    }, [])

    async function submitHandler() {
        if (!await validate()) return
        const { close, cancel, m_inputs } = await m_Confirm.show({ action: 'Submit' }, { condition: !!id })
        if (close || cancel) return
        add_edit_Feed(
            {
                id,
                instagram_post: inputs.instagram_post_filenames,
                descripition: inputs.descripition,
                toBePosted: inputs.toBePosted,
                track_id: inputs.track_id,
                comment: m_inputs?.comment
            },
            res => {
                if (res.status) navigate('/social_media/instagram')
            }
        )
    }

    function closeHandler(file) {
        setErrors(s => ({ ...s, instagram_post: '' }))
        setInputs(s => {
            return {
                ...s,
                instagram_post_filenames: s.instagram_post_filenames?.filter(a => a !== file.filename),
                instagram_post: s.instagram_post?.filter(a => a.filename !== file.filename),
            }
        })
    }

    async function handleFeedRequest(action) {
        const { cancel, m_inputs } = await Modals.instaFeedRequest({ action })
        if (cancel) return
        instaFeedRequestAction({
            id,
            submit_type: action._id,
            comment: m_inputs.comment
        }, () => {
            navigate('/social_media/instagram')
        })
    }

    return (
        <Page>

            <Page.Header>
                <Title title='Instagram' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                {!isView && !details?.for_approval &&
                    <TitleBar css={1} title={`${isEdit ? 'Edit' : 'Add'} Instagram Feed`} toggleButton>
                        <DatePick
                            css={1}
                            mb={20}
                            minDate={new Date()}
                            label='To Be Posted On'
                            width={300}
                            field='toBePosted'
                            inputSocket={formPlug}
                            required
                        />
                        <TextArea
                            css={2}
                            mb={20}
                            label='Description'
                            field='descripition'
                            inputSocket={formPlug}
                            required
                        />
                        <FileUpload
                            css={2}
                            label='Add Files'
                            validFilesOnly
                            FUref={furef}
                            dragNdrop
                            moreText='Drag and Drop your incident related file or Click here'
                            buttonLabel=""
                            icon={'/assets/icons/upload.svg'}
                            field='instagram_post'
                            multiple
                            inputSocket={formPlug}
                            required
                            accept={{ items: ['jpg', 'png', 'jpeg', 'mp4'], message: 'Only jpg, png, jpeg and mp4 formats accepted' }}
                            maxFileSizeMB={50}
                            progress={fileUploadProgress}
                        />
                    </TitleBar>}
                {inputs.instagram_post?.length > 0 &&
                    <TitleBar css={1} title={isView ? 'View Instagram Feed' : 'Instagram Feed Preview'} toggleButton>
                        {(isView || details?.for_approval) &&
                            <>
                                <LabelValue label={'To Be Posted On'} value={<DateViewer dateTime={inputs?.toBePosted} dateOnly />} mb={30} /><br />
                                <LabelValue label={'Description'} value={inputs?.descripition} mb={30} />
                            </>
                        }
                        <Flex columns={5} gap={15} >
                            {
                                inputs.instagram_post.map((file, i) => {
                                    return <Insta_Card key={i} file={file}
                                        showCloseIcon={!(isView || details?.for_approval)}
                                        onClose={(e) => {
                                            let posts = inputs.instagram_post.filter(a => a.url !== e.url)
                                            let filenames = inputs.instagram_post_filenames.filter(a => a !== e.filename)
                                            setInputs(s => ({ ...s, instagram_post: posts, instagram_post_filenames: filenames }))
                                            furef.current && furef.current(posts)
                                        }}
                                    />
                                })
                            }
                        </Flex>
                    </TitleBar>
                }
                {details?.instagramFeedsHistory.length > 0 &&
                    <TitleBar css={1} title={'History'} toggleButton>
                        {
                            details?.instagramFeedsHistory.map((his, i) => {
                                return (
                                    <div key={i} className="instagramHistory">
                                        <div className="icon">
                                            {<Image src={`${IMG_BASE_URL}profile/${his.handled_by?.profile_image}`} />}
                                        </div>
                                        <div className="name">
                                            <h2>{his?.handled_by['first_name' + _lang]}</h2>
                                            <p>{his?.comment}</p>
                                        </div>
                                        <DateViewer dateTime={his?.updatedAt} fs={12} />
                                        <StatusBox css={1} status={his?.status.request_status} width={200} mr={20} />
                                    </div>
                                )
                            })
                        }
                    </TitleBar>
                }
                {
                    user?.approve_instagram_feeds && details?.for_approval && isEdit &&
                    <TitleBar TitleBar css={1} title={'Instagram Feed Requests'} toggleButton>
                        <Flex columns={7} gap={10}>
                            <Button css={1.1} onClick={() => handleFeedRequest(statuses.approved)} content='Approve' />
                            <Button css={1} onClick={() => handleFeedRequest(statuses.rejected)} content='Reject' />
                            <Button css={1} onClick={() => handleFeedRequest(statuses.change_requested)} content='Request Change' />
                        </Flex>
                    </TitleBar>
                }

                <FooterButtons>
                    <Button right content="Back" css={1} icon="/assets/icons/back.svg" reverse iconWidth="15px" onClick={() => navigate(-1)} />
                    {!isView && !details?.for_approval && <Button left content="Submit" css={1} onClick={submitHandler} disabled={fileUploadLoader} />}
                    {!isView && !details?.for_approval && <Button left content="Cancel" css={1} onClick={() => navigate(-1)} />}
                </FooterButtons>

            </Page.Body>

        </Page >
    )
}