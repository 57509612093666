import React from 'react'
import './DateViewer.scss'
import moment from 'moment'
import { classMaker } from '../..'

export default function DateViewer({ className = '', css, dateTime, dateOnly, timeOnly, condition, fs }) {

  if (!condition && condition !== undefined) return null

  return (
    <span id='DateViewer' className={classMaker(className, css, 'DateViewer')} style={{ fontSize: fs }}>
      {dateTime ?
        <span dir='ltr'>
          {timeOnly
            ? moment(dateTime).format('hh:mm a')
            : dateOnly
              ? moment(dateTime).format('DD MMM YYYY')
              : (
                moment(dateTime).format('DD MMM YYYY') + ', ' +
                moment(dateTime).format('hh:mm a')
              )
          }
        </span>
        : '--'
      }
    </span >
  )
}
