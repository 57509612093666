import React, { useContext } from 'react'
import './Others.scss'
import { CommonContext } from '../..'
import { AppContext } from '../../App'
import { useLocation, useNavigate } from 'react-router-dom'
import config from '../../../common/config'

export default function Others({ content }) {

    const { errorPages } = useContext(AppContext)
    const { errors } = useContext(CommonContext)

    let isOffline = errors.isOffline
    let is404 = errors.is404
    let isLoading = Boolean(errors.isLoading)


    if (isOffline || is404 || isLoading)
        return (
            <>
                <style>{`html{height:auto}body{overflow:hidden}`}</style>
                {isOffline
                    ? errorPages.offline
                    : is404
                        ? errorPages.pageNotFound
                        : isLoading
                            ? errorPages.Loader
                            : null}
            </>
        )
    else
        return content
}


export const NetworkLost = () => {

    const location = useLocation()

    function networkRetryHandler() {
        window.location.reload()
    }

    return <div className='css_oth'>
        {config._network_lost
            ? React.cloneElement(config._network_lost, { retry: networkRetryHandler })
            : <div id='_network_lost'>
                <p>Network Connection Lost</p>
                <button onClick={() => networkRetryHandler()}>Retry</button>
            </div>
        }
    </div>
}

export const PageNotFound = () => {
    const navigate = useNavigate()
    return <div className='css_oth'>
        {config._page_not_found ||
            <div id='_page_not_found'>
                <p>Page Not Found</p>
                <button onClick={() => navigate('/')}>Home</button>
            </div>
        }
    </div>
}

export const AppLoader = () => {
    return <div className='css_oth'>
        {config._loader ||
            <span id="_Loader"></span>
        }
    </div>
}