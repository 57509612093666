import React from 'react'
import './Insta_Card.scss'

export default function Insta_Card({ file, onClose, showCloseIcon }) {
    return (
        <div id='Insta_Card'>
            <div className='header'>
                <img className='logo' src="/assets/img/Instagram_logo.png" alt="" />
            </div>
            <div className='profile'>
                <img className='profile_pic' src="/assets/img/instagram-profile-pic.jpg" alt="" />
                <p className='profile_name'>
                    alameenservice
                    <img className='verified' src="/assets/icons/insta_verified.svg" alt="" />
                </p>
            </div>
            <div className='content'>
                {file?.isInvalid
                    ? <div className='invalid'>Invalid File</div>
                    : file.type === 'mp4'
                        ? <video src={file.url} controls />
                        : <img src={file.url} alt="" />
                }
            </div>
            <div className='options'>
                <div className="icons">
                    <img src="/assets/img/instagram_like.png" alt="" />
                    <img src="/assets/img/instagram_comment.png" alt="" />
                    <img src="/assets/img/instagram_share.png" alt="" />
                </div>
                <p className='profile_name'>
                    alameenservice
                    <img className='verified' src="/assets/icons/insta_verified.svg" alt="" />
                </p>
            </div>
            {showCloseIcon && <img className='close_icon' src="/assets/icons/close_white.svg" alt="" onClick={() => onClose(file)} />}
        </div>
    )
}