import React, { useEffect, useRef, useState } from 'react'
import './List.scss'
import { classMaker } from '../..'

export default function List({ className, css, items, item, children, onEnd }) {

    // const scrollRef = useRef(false)
    const [flag, setFlag] = useState(false);

    // useEffect(() => {
    // const handleScroll = async (e) => {
    //     const res = window.innerHeight - document.getElementById('List_bottom').getBoundingClientRect().bottom;
    //     if (res > 0) {
    //         if (!onEnd) return;
    //         if (!flag) {  // To prevent multiple calls to onEnd
    //             setFlag(true);
    //             onEnd();
    //             console.log('onEnd called');
    //         }
    //     } else {
    //         setFlag(false);
    //     }
    // };

    // window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    // return () => {
    // window.removeEventListener('scroll', handleScroll);
    // };
    // }, [flag, onEnd]);  // Add dependencies

    return (
        <ul id='List' className={classMaker(className, css, 'List')} >
            {
                items?.map((a, i) => {
                    if (children) {
                        return <li key={i}>
                            {children(a, i)}
                        </li>
                    } else {
                        let b = item && item(a)
                        return (
                            <li key={i} className='item'>{b || a}</li>
                        )
                    }
                })
            }
        </ul>
    )
}

List.Bullet = ({ src }) => {
    return <img className='•' src={src} alt="" />
}