import React, { useEffect, useRef } from 'react'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Page from '../../_common/_components/Page/Page'
import Table from '../../_common/_components/Table/Table'
import DatePick from '../../_common/_components/DatePick/DatePick'
import Select from '../../_common/_components/Select/Select'
import Button from '../../_common/_components/Button/Button'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'
import Image from '../../_common/_components/Image/Image'
import { useNavigate } from 'react-router'
import Actions from '../../_common/_components/Actions/Actions'
import FilterBox from '../../_common/_components/FilterBox/FilterBox'
import { IMG_BASE_URL } from '../../config'
import useFetch from '../../_common/_Hooks/useFetch'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import useFilter from '../../_common/_Hooks/useFilter'
import useTranslate from '../../_common/_Hooks/useTranslate'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { filterDateSetter } from '../../helpers'
import { socket } from '../../components/InitialApis'
import { toast } from 'react-toastify'

export default function ManageRequests({ isMyRequest, isStatus }) {

    const navigate = useNavigate()
    const { _lang } = useTranslate()

    const id = useParams()

    const department = useSelector(s => s.department)
    const user = useSelector(s => s.user)

    const requestTypes = [
        { label: 'Manual', value: 'manual' },
        { label: 'Call Back', value: 'call_back' },
        { label: 'Voice Mail', value: 'voice_mail' },
        { label: 'Mobile App', value: 'mobile_app' },
        { label: 'Website', value: 'website' },
        { label: 'Email', value: 'email' },
    ]

    // *********************************************************************************************************************
    const { req: getRequests, res: requests_res } = useFetch({
        endpoint: 'request/getRequest',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: getMyRequests, res: myRequests_res } = useFetch({
        endpoint: 'request/getMyRequest',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    // const { res: incidentTypes_res } = useFetch({
    //     endpoint: 'incidentType/getIncidentTypeInRequest',
    //     method: 'post',
    //     type: 'raw',
    //     selfCall: true,
    //     privateLoader: true
    // })
    // *********************************************************************************************************************
    // const { res: departments_res } = useFetch({
    //     endpoint: 'department/getDepartmentInRequest',
    //     method: 'post',
    //     type: 'raw',
    //     selfCall: true,
    //     privateLoader: true
    // })
    // // *********************************************************************************************************************
    // const { res: agents_res } = useFetch({
    //     endpoint: 'request/getAgentsInRequest',
    //     method: 'post',
    //     type: 'raw',
    //     selfCall: true,
    //     privateLoader: true
    // })
    // *********************************************************************************************************************
    const { res: filters } = useFetch({
        endpoint: 'filtersForRequest',
        method: 'post',
        type: 'raw',
        selfCall: isMyRequest ? { myrequest: true } : true,
        privateLoader: true,
        dependency: [isMyRequest]
    })
    // *********************************************************************************************************************
    // const { res: statuses_res } = useFetch({
    //     endpoint: 'common/getAllStatus',
    //     method: 'get',
    //     type: 'raw',
    //     toast: false,
    //     selfCall: true,
    //     privateLoader: true
    // })
    // *********************************************************************************************************************
    const requests = isMyRequest ? myRequests_res?.data : requests_res?.data
    const pageDetails = isMyRequest ? myRequests_res?.page : requests_res?.page
    // const incidentTypes = incidentTypes_res?.data
    // const departments = departments_res?.data
    // const agents = agents_res?.data
    // const statuses_for_filter = ["resolved", "forward", "processing", "closed", "to_do", "assigned", "waiting_approval", "need_info", "escalated", "rejected", "approved", "reopen"]
    // if (isMyRequest) statuses_for_filter.push("draft")
    // const statuses = statuses_res?.data.filter(s => statuses_for_filter.includes(s.handle_key))
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: (filter) => {
            // if (filter.startDate) filter.startDate = new Date(filter.startDate).toISOString().split('T')[0]
            // filterDateSetter(filter, 'startDate')
            if (isStatus) filter.status = id
            if (isMyRequest) {
                filter.assigned_to = user._id
                getMyRequests(filter)
            } else {
                getRequests(filter)
            }
        }
    })

    const filterDataRef = useRef(filterData);

    useEffect(() => {
        filterDataRef.current = filterData;
    }, [filterData]);

    function handleNotification(e) {
        getRequests(filterDataRef.current, { privateLoader: true })
    }

    useEffect(() => {
        socket.on('notification', handleNotification)
        return () => socket.off('notification', handleNotification)
    }, [])

    return (
        <Page className={isMyRequest ? 'incident_myrequests' : 'incident_requests'}>

            <Page.Header>
                <Title title={isMyRequest ? 'My Requests' : 'Requests'} />
                <Breadcrumb />
                {user?.create_incident_request && <Button content='Add Request' css={1.1} icon={'/assets/icons/plus.svg'} reverse onClick={() => navigate('add_request')} />}
            </Page.Header>

            <Page.Body css={isStatus ? 0 : 1} >
                {
                    !isStatus &&
                    <FilterBox
                        _css={{ Select: 1, DatePick: 1, Input: 1, Search_Btn: 1.1, Clear_Btn: 1.2 }}
                        _placeholder={{ Select: 'Select', Input: 'Type in input' }}
                        showCloseBtns
                        filterHandler={filterHandler}
                        filterData={filterData}
                        dependency={[isMyRequest]}
                    >
                        <DatePick label='Start Date' field='startDate' maxDate={(e) => e.endDate} dateValueOnly />
                        <DatePick label='End Date' field='endDate' minDate={(e) => e.startDate} dateValueOnly />
                        <Select label='Department' field='department_id' content={{ op: filters?.departmentDataList, lb: 'department_name' + _lang, vl: '_id' }} />
                        <Select label='Incident Type' field='incidentType' content={{ op: filters?.incidentTypeList, lb: 'incident_type' + _lang, vl: '_id' }} />
                        <Select label='Status' field='status' content={{ op: filters?.statusFilter, lb: 'request_status', vl: '_id' }} />
                        <Select label='Request Type' field='request_type' content={{ op: requestTypes, lb: 'label', vl: 'value' }} />
                        <Select label='Handled By' field='agentId' content={{ op: filters?.agentDataList, lb: 'first_name' + _lang, vl: '_id' }} condition={!isMyRequest} />
                    </FilterBox>
                }
                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Ticket No', key: 'ticket_no', sort: true, width: 100 },
                        { title: 'Created Date', key: 'created_date', sort: true, last: 4, width: 100 },
                        { title: 'Handled By', key: 'created_by', sort: true, last: 3, width: 150 },
                        { title: 'Customer Name', key: 'cutomer_name', sort: true, last: 1 },
                        { title: 'Phone Number', key: 'customer_contact', sort: true, dir: 'ltr', d_align: 'right' },
                        { title: 'Request Type', key: 'request_type', sort: true, last: 2 },
                        { title: 'Incident Type', key: 'incident_type', sort: true },
                        { title: 'Assigned Department', key: 'department', sort: true },
                        { title: 'Status', key: 'status', sort: true }
                    ]}
                    content={requests?.map((request) => {
                        return {
                            id: request._id,
                            ticket_no: request.ticket_no,
                            created_date: <DateViewer dateTime={request.createdAt} dateOnly />,
                            created_by: request.handled_by
                                ? <Image src={`${IMG_BASE_URL}profile/${request.handled_by?.profile_image}`} label={request.handled_by?.['first_name' + _lang]} />
                                : '-',
                            cutomer_name: request?.customer_name_en,
                            customer_contact: request?.customer_contact ? ((request?.customer_contact_code || '+971') + ' ' + request?.customer_contact) : '-',
                            request_type: <StatusBox css={2} status={request.request_type} />,
                            incident_type: request?.incident_type?.['incident_type' + _lang],
                            department: request?.department?.['department_name' + _lang],
                            status: <StatusBox css={1} status={request.status?.request_status} />
                        }
                    })}
                    onRowClick={(e) => {
                        isMyRequest
                            ? F_navigate(`/incident/myrequests/view_request/${e.id}`)
                            : F_navigate(`/incident/requests/view_request/${e.id}`)
                    }}
                    pageDetails={pageDetails}
                    filterHandler={filterHandler}
                    filterData={filterData}
                    dependency={[isMyRequest]}
                />
            </Page.Body>

        </Page>
    )
}