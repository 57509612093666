import React, { useEffect, useRef, useState } from 'react'
import './DatePick.scss'
import DatePicker from "react-datepicker";
import CalendarIcon from "./DatePick_calendar.svg"
import ClockIcon from "./DatePick_clock.svg"
import { classMaker } from '../..';
import useTranslate from '../../_Hooks/useTranslate';
import "react-datepicker/dist/react-datepicker.css";

let validationObj = {
  minDate: { message: '', condition: '' },
  maxDate: { message: '', condition: '' },
}
export default function DatePick({ className, css, label, value, onChange, field, dateFormat, required, inputSocket, maxDate, minDate, disabled, placeholder = 'Select', error, showCloseBtn, showMonthDropdown, showYearDropdown, validation = validationObj, timeSelect, minTime, maxTime, icon, condition, clearOnHide, width, hideStar, mb, mt, mr, ml, onBlur, filterDate, timeSelectOnly }) {
  let connector = inputSocket?.connector
  error = error || inputSocket?.errors
  value = value || inputSocket?.inputs
  onChange = onChange || inputSocket?.inputHandler

  const { t } = useTranslate()
  const dpRef = useRef()

  const [inError, setInError] = useState('')
  const [inValue, setInValue] = useState('')

  useEffect(() => {
    setInValue(() => {
      let res = ''
      if (typeof value === 'object' && !(value instanceof Date) && value?.[field] !== undefined) res = value?.[field]
      else if (typeof value === 'object' && !(value instanceof Date) && value?.[field] === undefined) res = ''
      else if (value !== undefined) res = value
      return res
    })
    if (!field) setInError('Please add field')
  }, [value])

  useEffect(() => {
    if (timeSelect) {
      setTimeout(() => {
        dpRef.current.querySelector('input').setAttribute('readonly', true)
      }, 0);
    }
  }, [])

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field }
      if (required) obj.required = required
      if (ifObjectExist(validation.minDate)) obj.minDate = validation.minDate
      if (ifObjectExist(validation.maxDate)) obj.maxDate = validation.maxDate
      connector && connector(obj)
      if (Object.keys(obj).length > 1 && !connector) setInError("Please connect 'formPlug' to 'inputSocket'")
    }
    return () => { connector && connector({ key: field, clearValidation: true }) }
  }, [...(required && typeof required === 'object' ? Object.values(required) : [required]), condition])

  useEffect(() => {
    return () => {
      condition && clearOnHide && connector && connector({ key: field, clearValidation: true, clearValue: true })
    }
  }, [condition])

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join('').length > 0 || obj === true
  }

  function changeHandler(e) {
    let d = timeSelect
      ? e
      : new Date(new Date(e).toDateString())
    setInValue(d)
    onChange && onChange(d || '', field)
  }

  if (!condition && condition !== undefined) return null

  return (
    <span id='DatePick' className={classMaker(className, css, 'DatePick') + (timeSelect ? " time-select" : "")} style={{ width: width, marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml }}>
      {(label || label === '') && <label>{t(label)}{(required?.condition !== undefined ? required?.condition : required) && !hideStar && <em>*</em>}</label>}
      <span ref={dpRef} className='datepic_wrapper'>
        <DatePicker
          selected={inValue}
          onChange={changeHandler}
          dateFormat={dateFormat || (timeSelect ? 'MMM d, yyyy h:mm aa' : 'dd/MM/YYYY')}
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={t(placeholder)}
          disabled={disabled}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          showTimeSelect={timeSelect}
          showTimeSelectOnly={timeSelectOnly}
          timeIntervals={15}
          minTime={minTime}
          maxTime={maxTime}
          dropdownMode="select"
          onBlur={onBlur && onBlur}
          filterDate={filterDate}
        />
        {(showCloseBtn && value)
          ? <span className='close_btn' onClick={() => { onChange && onChange('', field) }}>✖</span>
          : <img className='calendar' src={icon ? icon : (timeSelectOnly ? ClockIcon : CalendarIcon)} alt="" />
        }
      </span>
      {(inError || (typeof error === 'object' ? error?.[field] : error)) && <small id='error' className='error'>{inError || (typeof error === 'object' ? t(error?.[field] || '') : t(error))}</small>}
    </span>
  )
}
DatePick.displayName = 'DatePick'

{/* 

<DatePick
  className=''
  title=''
  placeholder=''
  selected=''
  onChange={(value) => { }}
  dateFormat=''
  maxDate=''
  minDate=''
  showCloseBtn
  required
  inputSocket={() => { }}
  error=''
  field=''
/>

*/}