import React, { useEffect } from 'react'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Table from '../../_common/_components/Table/Table'
import useFetch from '../../_common/_Hooks/useFetch'
import useTranslate from '../../_common/_Hooks/useTranslate'
import Page from '../../_common/_components/Page/Page'
import useFilter from '../../_common/_Hooks/useFilter'
import Actions from '../../_common/_components/Actions/Actions'
import Button from '../../_common/_components/Button/Button'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import useModal from '../../_common/_Hooks/useModal'
import { Add_Edit_IncidentType } from '../../common/Modals'

export default function IncidentTypes() {

    const { _lang } = useTranslate()
    const m_Add_Edit_IncidentType = useModal(<Add_Edit_IncidentType />)

    // *********************************************************************************************************************
    const { req: getIncidentType, res: agents_res } = useFetch({
        endpoint: 'incidentType/getIncidentType',
        method: 'post',
        type: 'formdata',
    })
    // *********************************************************************************************************************
    const { req: addIncidentType } = useFetch({
        endpoint: "incidentType/addIncidentType",
        method: "post",
        type: "raw",
        toast: true
    })
    // *********************************************************************************************************************
    const { req: deleteIncidentType } = useFetch({
        endpoint: "incidentType/deleteIncidentType",
        method: "delete",
        type: "params",
        toast: true
    })
    // *********************************************************************************************************************
    const { req: updateIncidentType } = useFetch({
        endpoint: "incidentType/updateIncidentType",
        method: "put",
        type: "raw",
        toast: true
    })
    // *********************************************************************************************************************
    const { req: getIncidentDetails } = useFetch({
        endpoint: "incidentType/getIncidentTypeDetail",
        type: 'params',
        method: "get",
    })
    // *********************************************************************************************************************
    let incidentTypes = agents_res?.data
    let pageDetails = agents_res?.page
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate, initialFilter } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getIncidentType
    })

    async function addHandler() {
        let { close, cancel, m_inputs } = await m_Add_Edit_IncidentType.show()
        if (close || cancel) return
        let obj = {
            incident_type_en: m_inputs?.incident_type_en,
            incident_type_ar: m_inputs?.incident_type_ar,
            sub_incident_types: m_inputs?.subTypes
        }
        addIncidentType(obj, () => getIncidentType(initialFilter))
    }

    async function editHandler(data) {
        const details = await getIncidentDetails(data._id)
        const incidentTypeDetails = details.data.incidentTypeDetails
        const subTypeDetails = details.data.subIncidentTypeData
        let { close, cancel, m_inputs } = await m_Add_Edit_IncidentType.show({ m_inputs: { incidentTypeDetails, subTypeDetails }, isEdit: true })
        if (close || cancel) return
        let obj = {
            incidentTypeId: data._id,
            incident_type_en: m_inputs?.incident_type_en,
            incident_type_ar: m_inputs?.incident_type_ar,
            sub_incident_types: m_inputs?.subTypes,
            deleteSubIncidentType: m_inputs?.deleted
        }
        updateIncidentType(obj, () => {
            getIncidentType(initialFilter)
        })
    }

    async function deleteHandler(id) {
        let { cancel } = await Modals.Confirm({ action: 'Delete' })
        if (cancel) return
        deleteIncidentType(id, () => { getIncidentType(initialFilter) })
    }

    return (
        <Page>

            <Page.Header>
                <Title title='Incident Types' />
                <Breadcrumb />
                <Button width={190} content='Add Incident Type' css={1.1} icon={'/assets/icons/plus.svg'} reverse onClick={addHandler} />
            </Page.Header>

            <Page.Body>

                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Sl No', key: 'sl_no' },
                        { title: 'Incident Type (EN)', key: 'incident_type_en', sort: true },
                        { title: 'Incident Type (AR)', key: 'incident_type_ar', sort: true },
                        { title: 'Action', key: 'action', },
                    ]}
                    content={
                        incidentTypes?.map(((a, i) => {
                            return {
                                sl_no: (filterData.page * filterData.perPage) + (i + 1),
                                incident_type_en: a.incident_type_en,
                                incident_type_ar: a.incident_type_ar,
                                action: <Actions buttons={{ edit: '/assets/icons/edit.svg', delete: '/assets/icons/delete.svg' }}
                                    onEdit={() => editHandler(a)}
                                    onDelete={() => deleteHandler(a._id)}
                                />,
                            }
                        }))}
                    pageDetails={pageDetails}
                    filterData={filterData}
                    filterHandler={filterHandler}
                />
            </Page.Body>
        </Page>
    )
}
