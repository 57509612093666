import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'

export default function UpcomingFeeds() {
    return (
        <Page className='upcomingFeeds'>
            <Page.Header>
                <Title title='Upcoming Feeds' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title='Upcoming Feeds'>
                    <div className='files'>
                        <img src="/data/1.jpg" alt="" />
                        <img src="/data/2.jpg" alt="" />
                        <img src="/data/3.jpg" alt="" />
                        <img src="/data/4.jpg" alt="" />
                    </div>
                    <LabelValue label='Description' value='
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, est unde, tempore rem distinctio nobis doloremque eius saepe atque culpa, debitis inventore. Perspiciatis nemo vel, ipsa animi quod laborum alias.
                    ' />
                </TitleBar>
            </Page.Body>

        </Page>
    )
}
