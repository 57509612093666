import React, { useEffect, useState } from 'react'
import './Checkbox.scss'
import { v4 } from 'uuid'
import useTranslate from '../../_Hooks/useTranslate'
import { classMaker } from '../..'

export default function Checkbox({ className, css, label = 'Label', inputClassName, value, required, error, field, onChange, reverse, size, toggleSwitch, inputSocket, condition, clearOnHide, disabled, hideStar, mb, mt, mr, ml }) {

  let connector = inputSocket?.connector
  error = error || inputSocket?.errors
  value = value || inputSocket?.inputs
  onChange = onChange || inputSocket?.inputHandler

  let uuid = v4()
  const { t } = useTranslate()
  const [inError, setInError] = useState('')

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field }
      if (required) obj.required = required
      connector && connector(obj)
      if (Object.keys(obj).length > 1 && !connector) setInError("Please connect 'formPlug' to 'inputSocket'")
    }
    if (!field) setInError('Please add field')
    return () => { connector && connector({ key: field, clearValidation: true }) }
  }, [...(required && typeof required === 'object' ? Object.values(required) : [required]), condition])

  useEffect(() => {
    return () => {
      condition && clearOnHide && connector && connector({ key: field, clearValidation: true, clearValue: true })
    }
  }, [condition])

  function checkedHandler(checked) {
    let res = false
    if (typeof checked === 'object' && checked?.[field] !== undefined) res = checked?.[field]
    else if (typeof checked === 'object' && checked?.[field] === undefined) res = false
    else if (checked !== undefined) res = checked
    return res
  }

  if (!condition && condition !== undefined) return null

  return (
    <span id='Checkbox' className={classMaker(className, css, 'Checkbox')} style={{ marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml }}>
      <span className={reverse ? 'reverse' : ''}>
        {label && <label htmlFor={uuid}>{t(label)}{(required?.condition !== undefined ? required?.condition : required) && !hideStar && <em>*</em>}</label>}
        {toggleSwitch
          ? <ToggleSwitch
            label={toggleSwitch}
            onChange={(e) => onChange && onChange(e, field)}
            value={checkedHandler(value)}
            disabled={disabled}
          />
          : <input
            type="checkbox"
            className={inputClassName || ''}
            style={{ width: size, height: size }}
            id={uuid}
            checked={checkedHandler(value)}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => {
              onChange && onChange(e.target.checked, field)
            }}
          />
        }

      </span>
      {(inError || (typeof error === 'object' ? error?.[field] : error)) && <small id='error'>{inError || (typeof error === 'object' ? t(error?.[field] || '') : t(error))}</small>}
    </span>
  )
}
Checkbox.displayName = 'Checkbox'

const ToggleSwitch = ({ onChange, label, value, disabled }) => {
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setToggle(typeof value === 'boolean' ? value : false)
  }, [value])

  let toggleHandler = () => {
    if (disabled) return
    setToggle(s => {
      let r = !s
      onChange && onChange(r)
      return r
    })
  }

  return (
    <span id='ToggleSwitch' className={disabled ? 'disabled' : ''} data-checked={toggle} onClick={toggleHandler} >
      {label[toggle] && <label htmlFor="">{label[toggle]}</label>}
      <span></span>
    </span >
  )
}