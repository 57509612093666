import React, { useContext, useEffect, useRef, useState } from 'react'
import Input from '../Input/Input'
import Button from '../Button/Button'
import useForm from '../../_Hooks/useForm'
import icon_password_hide from './logs_eye_hide.svg'
import icon_password_show from './logs_eye_show.svg'
import cryptoJs from 'crypto-js'
import usePasswordProtect from '../../_Hooks/usePasswordProtect'

const LoginContext = React.createContext()

export const Username = ({ css, mb, mt, labelGap }) => {

    const { formPlug, enterKeyHandler } = useContext(LoginContext) || {}

    return (
        <Input
            css={css}
            className='Username'
            label={'Username'}
            placeholder='Enter your username here'
            field={'username'}
            onKeyDown={(e) => enterKeyHandler(e, 1)}
            inputSocket={formPlug}
            required
            hideStar
            mb={mb}
            mt={mt}
            labelGap={labelGap}
        />
    )
}

export const Password = ({ css, mb, mt, labelGap }) => {

    const { formPlug, enterKeyHandler } = useContext(LoginContext) || {}
    const [showPassword, setShowPassword] = useState(false)

    const passwordRef = useRef()
    const eyeIconRef = useRef()

    usePasswordProtect({ passwordRef, eyeIconRef })

    return (
        <Input
            css={css}
            className='Password'
            ref={passwordRef}
            label={'Password'}
            type={showPassword ? 'text' : 'password'}
            placeholder='Enter your password here'
            icon={showPassword ? icon_password_hide : icon_password_show}
            field={'password'}
            onKeyDown={(e) => enterKeyHandler(e, 2)}
            inputSocket={formPlug}
            required
            hideStar
            onIconClick={() => setShowPassword(s => {
                let status = !s
                eyeIconRef.current = status
                return status
            })}
            mb={mb}
            mt={mt}
            labelGap={labelGap}
            autocomplete
        />
    )
}

export const Submit = ({ css, onClick, loader, icon, reverse, iconWidth, hashKey }) => {
    const { validate, inputs } = useContext(LoginContext) || {}

    async function clickHandler() {
        if (!await validate()) return
        onClick && onClick({
            ...inputs,
            // password: window.btoa(inputs.password)
            password: cryptoJs.AES.encrypt(inputs.password, hashKey).toString() + '_' + window.btoa(hashKey + '_' + inputs.username)
        })
    }

    return (
        <Button
            css={css}
            className='Submit'
            content='Login'
            onClick={clickHandler}
            loader={loader}
            icon={icon}
            reverse={reverse}
            iconWidth={iconWidth}
        />
    )
}

const Logs = ({ children }) => {

    const ref = useRef()
    const form = useForm()

    function enterKeyHandler(e, flag) {
        if (e.key === 'Enter') {
            flag === 1 && ref.current.querySelector('.Password input').focus()
            flag === 2 && ref.current.querySelector('.Submit').click()
        }
    }

    return (
        <div id='Logs' ref={ref}>
            <LoginContext.Provider value={{ ...form, enterKeyHandler }}>
                {children}
            </LoginContext.Provider >
        </div>
    )
}

Logs.Username = Username
Logs.Password = Password
Logs.Submit = Submit
export default Logs