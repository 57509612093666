import React, { useEffect, useRef } from 'react'
import './Page.scss'
import { classMaker } from '../..'

export default function Page({ className, css, children }) {

    return (
        <div id='Page' className={classMaker(className, css, 'Page')}>
            {children}
            {/* <footer>
                <p>{`Copyright © ${new Date().getFullYear()}`}</p>
                <p>Developed by SmartHatch</p>
            </footer> */}
        </div>
    )
}

const Header = ({ className, css, children }) => {

    const headerRef = useRef()

    useEffect(() => {
        headerRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }, [])

    return (
        <div id='Page_Header' className={classMaker(className, css, 'Page_Header')} ref={headerRef}>
            <div className='title'>
                {children && (!Array.isArray(children) ? [children] : children).filter(obj => (['Title', 'Breadcrumb'].includes(obj?.type?.displayName)))}
            </div>
            <div className='buttons'>
                {children && (!Array.isArray(children) ? [children] : children).filter(obj => (['Button', 'StatusBox'].includes(obj?.type?.displayName)))}
            </div>
        </div >
    )
}

const Body = ({ className, css, children }) => {
    return (
        <div id='Page_Body' className={classMaker(className, css, 'Page_Body')}>
            {children}
        </div>
    )
}

Page.Header = Header
Page.Body = Body