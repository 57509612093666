import React from 'react'
import NavBar from '../../_common/_components/NavBar/NavBar'
import routes from '../../routes'
import './sidebar.scss'

export default function SideBar() {

    return (
        <div className='main_sidebar'>
            <NavBar menuList={routes} />
        </div>
    )
}