import React, { useEffect, useRef } from "react";
import Title from "../../_common/_components/Title/Title";
import Breadcrumb from "../../_common/_components/Breadcrumb/Breadcrumb";
import Div from "../../_common/_components/Div/Div";
import Grid from "../../_common/_components/Grid/Grid";
import Input from "../../_common/_components/Input/Input";
import RadioButtons from "../../_common/_components/RadioButtons/RadioButtons";
import Select from "../../_common/_components/Select/Select";
import FooterButtons from "../../_common/_components/FooterButtons/FooterButtons";
import Button from "../../_common/_components/Button/Button";
import Checkbox from "../../_common/_components/Checkbox/Checkbox";
import useForm from "../../_common/_Hooks/useForm";
import { IMG_BASE_URL, SECRETKEY } from "../../config";
import useFetch from "../../_common/_Hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import FileUpload from "../../_common/_components/FileUpload/FileUpload"
import Page from "../../_common/_components/Page/Page";
import Flex from "../../_common/_components/Flex/Flex";
import cryptoJs from "crypto-js";
import { v4 } from 'uuid'
import usePasswordProtect from "../../_common/_Hooks/usePasswordProtect";
import useTranslate from "../../_common/_Hooks/useTranslate";
import { toast } from "react-toastify";

export default function AddUser() {

  const navigate = useNavigate();
  const { t, _lang } = useTranslate()
  const { id } = useParams()
  const isEditing = id
  const uuid = v4()

  const passwordRef = useRef()
  const confirmPasswordRef = useRef()

  usePasswordProtect({ passwordRef })
  usePasswordProtect({ passwordRef: confirmPasswordRef })

  const roleData = [
    { label: "User", value: "user" },
    { label: "Manager", value: "manager" },
    { label: "Sub Admin", value: "sub_admin" },
    { label: "Viewer", value: "viewer" },
  ];

  // *********************************************************************************************************************
  const { req: addUserRequest } = useFetch({
    endpoint: "user/addUser",
    method: "post",
    type: "raw",
    toast: true
  })
  // *********************************************************************************************************************
  const { req: getUser } = useFetch({
    endpoint: "user/getUserById",
    method: "get",
    type: "params",
  })
  // *********************************************************************************************************************
  const { res: departments_res } = useFetch({
    endpoint: "department/getDepartment",
    method: "post",
    type: "formdata",
    selfCall: true
  })
  // *********************************************************************************************************************
  const { req: uploadFile } = useFetch({
    endpoint: "file/uploadFile",
    method: "post",
    type: "formdata",
  });
  // *********************************************************************************************************************
  let departments = departments_res?.data
  // *********************************************************************************************************************

  const { inputs, setInputs, clearInputs, formPlug, validate, inputHandler, setErrors } = useForm({}, { fileUploader: uploadFile });

  useEffect(() => {
    getUserDetails()
  }, [])

  const getUserDetails = () => {
    isEditing && getUser(id, (res) => {
      let res_data = res?.data
      setInputs({
        employee_id: res_data?.employee_id,
        first_name_en: res_data?.first_name_en,
        first_name_ar: res_data?.first_name_ar,
        last_name_en: res_data?.last_name_en,
        last_name_ar: res_data?.last_name_ar,
        phone_number: res_data?.phone_number,
        email: res_data?.email,
        upload_instagram_feeds: res_data?.upload_instagram_feeds,
        view_instagram_feeds: res_data?.view_instagram_feeds,
        is_english: res_data?.is_english,
        gender: res_data?.gender,
        ...(res_data?.profile_image ? {
          profile_image: [{
            url: `${IMG_BASE_URL}profile/${res_data?.profile_image}`,
            filename: res_data?.profile_image
          }]
        } : {}),
        approve_instagram_feeds: res_data?.approve_instagram_feeds,
        manage_lecture_request: res_data?.manage_lecture_request,
        manage_volunteer_with_us: res_data?.manage_volunteer_with_us,
        download_files: res_data?.download_files,
        create_incident_request: res_data?.create_incident_request,
        password: res_data?.password,
        role: roleData.find(r => r.value === res_data?.role),
        is_agent: res_data?.is_agent,
        department: res_data?.department,
        incident_details: res_data?.viewer_permission?.includes('incident_details'),
        agents_details: res_data?.viewer_permission?.includes('agents_details'),
        call_details: res_data?.viewer_permission?.includes('call_details'),
      })
    })
  }

  const onSubmitHandler = async () => {
    if (!await validate()) return
    const viewerPermissions = Object.keys(inputs).filter(item => [
      "incident_details",
      "view_instagram_feeds",
      "agents_details",
      "call_details",
      "manage_lecture_request",
      "manage_volunteer_with_us",
      "is_english"
    ].includes(item) && inputs[item])
    if (viewerPermissions.length === 0 && inputs.role?.value === 'viewer') {
      toast.error('No permissions selected', { position: 'bottom-center' })
      return
    }
    if (inputs.password !== inputs.confirm_password) {
      setErrors(s => ({ ...s, confirm_password: 'Passwords do not match' }))
      return
    }
    addUserRequest(
      (arr) => ({
        userId: id,
        track_id: inputs.track_id,
        employee_id: inputs.employee_id,
        first_name_en: inputs.first_name_en,
        first_name_ar: inputs.first_name_ar,
        last_name_en: inputs.last_name_en,
        last_name_ar: inputs.last_name_ar,
        phone_number: inputs.phone_number,
        email: inputs.email,
        gender: inputs.gender,
        profile_image: inputs.profile_image?.[0]?.filename,
        // password: window.btoa(inputs.password),
        password: cryptoJs.AES.encrypt(inputs.password, uuid).toString() + '_' + window.btoa(uuid + '_' + inputs.username),
        role: inputs.role?.value,
        is_agent: inputs.is_agent,
        upload_instagram_feeds: inputs.upload_instagram_feeds,
        view_instagram_feeds: inputs.view_instagram_feeds,
        is_english: inputs.is_english,
        approve_instagram_feeds: inputs.approve_instagram_feeds,
        manage_lecture_request: inputs.manage_lecture_request,
        manage_volunteer_with_us: inputs.manage_volunteer_with_us,
        download_files: inputs.download_files,
        create_incident_request: inputs.create_incident_request,
        viewer_permission: viewerPermissions,
        departmentId: Array.isArray(inputs.department)
          ? inputs.department.map(d => {
            return d._id
          })
          : [inputs.department._id]
      }),
      (res) => {
        if (res.status) navigate('/team/manage_users');
      }
    )
  }

  const onCancel = () => {
    if (isEditing) {
      getUserDetails()
    } else {
      clearInputs()
    }
  }

  return (
    <Page className='AddUser'>

      <Page.Header>
        <Title title="Manage Users" />
        <Breadcrumb />
      </Page.Header>

      <Page.Body css={1} >
        <Div css={1}>
          <div className="upload_photo">
            <span className="upload__img">
              {
                inputs.profile_image?.[0]?.url
                  ? <img className={"photo uploaded"} src={inputs.profile_image?.[0]?.url} alt="" />
                  : <img className={"photo"} src={'/assets/img/camera.svg'} alt="" />
              }
            </span>
            <div>
              <FileUpload
                css={1}
                label=""
                inputSocket={formPlug}
                buttonLabel="Upload Photo"
                maxFileSizeMB={5}
                accept={{ items: ["jpg", "jpeg", "png"], message: 'Only jpg, jpeg and png formats accepted' }}
                field="profile_image"
              // required={{ message: "Please upload photo", }}
              />
              <p>At least 200 * 200 px recommended<br />JPG or PNG is allowed</p>
            </div>
          </div>
          <Grid columns={3} colGap={15}>
            <Input
              css={1}
              label="Employee ID"
              field={"employee_id"}
              inputSocket={formPlug}
              required
            />
            <Input
              css={1}
              label="First Name [En]"
              field={"first_name_en"}
              inputSocket={formPlug}
              required
            />
            <Input
              css={1}
              label="Last Name [En]"
              field={"last_name_en"}
              inputSocket={formPlug}
            // required
            />
            <Input
              css={1}
              label="First Name [Ar]"
              field={"first_name_ar"}
              inputSocket={formPlug}
              required
            />
            <Input
              css={1}
              label="Last Name [Ar]"
              field={"last_name_ar"}
              inputSocket={formPlug}
            // required
            />
            <RadioButtons
              css={1}
              label="Gender"
              field={"gender"}
              inputSocket={formPlug}
              buttons={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
              ]}
              required
            />
            <Input
              css={1}
              label="Email"
              field={"email"}
              inputSocket={formPlug}
              validation={{ email: { message: "Invalid email" } }}
              required
            />
            <Input
              css={1}
              label="Phone Number"
              field={"phone_number"}
              inputSocket={formPlug}
              type='number'
              validation={{ pattern: '+971 _____  _____' }}
              required
            />
          </Grid>
          {!isEditing && <>
            <Grid columns={3} colGap={15} mt={20}>

              <Input
                css={1}
                ref={passwordRef}
                label="Password"
                field={"password"}
                type='password'
                inputSocket={formPlug}
                validation={{ minChar: { length: 8 }, }}
                required
              />
              <Input
                css={1}
                ref={confirmPasswordRef}
                label="Confirm Password"
                field={"confirm_password"}
                type='password'
                inputSocket={formPlug}
                required
              />
            </Grid>
          </>
          }
          <Grid columns={3} colGap={15} mt={20}>
            <Select
              css={1}
              label="Role"
              field={"role"}
              inputSocket={formPlug}
              onChange={(value, key) => {
                setInputs(s => ({ ...s, department: '' }))
                inputHandler(value, key)
              }}
              content={{ op: roleData, lb: 'label', vl: 'value' }}
              required
            />
            <Select
              css={1}
              Multi2={inputs.role?.value === 'sub_admin'}
              label="Department"
              field={"department"}
              inputSocket={formPlug}
              content={{ op: departments, lb: 'department_name' + _lang, vl: '_id' }}
              required
            />
            {(inputs.department?.handle_key !== 'customer_service' && inputs.role?.value !== "sub_admin") && inputs.role?.value !== 'viewer' &&
              < Checkbox
                css={1}
                className="mt-4 pt-3"
                label="Is Agent"
                field={"is_agent"}
                inputSocket={formPlug}
                onChange={(value, key) => {
                  value === false && setInputs(s => ({
                    ...s, department: Array.isArray(s.department)
                      ? s.department.filter(d => d.handle_key !== 'customer_service')
                      : ''
                  }))
                  inputHandler(value, key)
                }}
                reverse
              />}
          </Grid>
        </Div>
        <Div css={1}>
          <h4 className="subtitle">{t('Add Permissions')}</h4>
          <Flex mt={30} columns={5} gr={20}  >
            {inputs.role?.value === 'viewer' &&
              <Checkbox
                css={1}
                label="Incident Details"
                field={"incident_details"}
                inputSocket={formPlug}
                reverse
              />
            }
            {inputs.role?.value !== 'viewer' &&
              <Checkbox
                css={1}
                label="Create Instagram Feeds"
                field={"upload_instagram_feeds"}
                inputSocket={formPlug}
                reverse
              />}
            <Checkbox
              css={1}
              label="View Instagram Feeds"
              field={"view_instagram_feeds"}
              inputSocket={formPlug}
              reverse
            />
            {inputs.role?.value !== 'viewer' &&
              <>
                <Checkbox
                  css={1}
                  label="Approve Instagram Feeds"
                  field={"approve_instagram_feeds"}
                  inputSocket={formPlug}
                  reverse
                />
                <Checkbox
                  css={1}
                  label="Create New Incident Request"
                  field={"create_incident_request"}
                  inputSocket={formPlug}
                  reverse
                />
                <Checkbox
                  css={1}
                  label="Download Files"
                  field={"download_files"}
                  inputSocket={formPlug}
                  reverse
                />
              </>
            }
            {inputs.role?.value === 'viewer' &&
              <>
                <Checkbox
                  css={1}
                  label="Agents Details"
                  field={"agents_details"}
                  inputSocket={formPlug}
                  reverse
                />
                <Checkbox
                  css={1}
                  label="Call Details"
                  field={"call_details"}
                  inputSocket={formPlug}
                  reverse
                />
              </>
            }
            <Checkbox
              css={1}
              label="Lecture Request"
              field={"manage_lecture_request"}
              inputSocket={formPlug}
              reverse
            />
            <Checkbox
              css={1}
              label="Volunteer with Us"
              field={"manage_volunteer_with_us"}
              inputSocket={formPlug}
              reverse
            />
            <Checkbox
              css={1}
              label="Language - EN"
              field={"is_english"}
              inputSocket={formPlug}
              reverse
            />
          </Flex>
        </Div>

        <FooterButtons>
          <Button right content="Back" css={1} icon="/assets/icons/back.svg" reverse iconWidth="15px" onClick={() => navigate(-1)} />
          <Button left content="Submit" css={1} onClick={() => onSubmitHandler()} />
          <Button left content="Cancel" css={1} onClick={onCancel} />
        </FooterButtons>

      </Page.Body>

    </Page>
  )
}