import React, { useEffect, useRef, useState } from 'react'

export default function usePatternMasking({ onChange, onKeyDown, pattern = '', value, type, label, message }) {

    const placeholder = '_'
    const removables = useRef(new Set(pattern.match(new RegExp(`[^${placeholder}]+`, 'gm'))))
    removables.current.add('_')

    const [state, setState] = useState({ val: pattern, index: [] })

    useEffect(() => {
        if (value) {
            if (pattern) {
                let resValue = pattern
                let indexes = []
                String(value).split('').forEach(valKey => {
                    indexes.push(resValue.indexOf(placeholder))
                    resValue = resValue.replace(placeholder, valKey)
                })
                setState({ val: resValue, index: indexes })
            } else {
                setState({ val: String(value), index: [] })
            }
        }
        return () => {
            setState({ val: pattern, index: [] })
        }
    }, [value])


    async function PM_keyDownHandler(e) {
        if (pattern) {
            let res = await stateSetter(e)
            onChangeCaller(res)
        }
        onKeyDown && onKeyDown(e)
    }

    async function stateSetter(e) {
        return new Promise((resolve) => {
            if (type === 'number' ? ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) : (e.keyCode >= 65 && e.keyCode <= 90) || ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105))) {
                setState((s) => {
                    let res = s.val.replace(placeholder, e.key)
                    let valIndex = s.val.indexOf(placeholder)
                    resolve(res)
                    return { val: res, index: valIndex === -1 ? s.index : [...s.index, valIndex] }
                })
            } else if (e.keyCode === 8) {
                setState(s => {
                    if (s.index.length === 0) return s
                    let ind = s.index[s.index.length - 1]
                    let res = s.val.substring(0, ind) + placeholder + s.val.substring(ind + 1)
                    resolve(res)
                    return { val: res, index: s.index.slice(0, -1) }
                })
            }
        })
    }

    function onChangeCaller(val, e) {
        let patternVal = val
        removables.current.forEach((rm) => {
            val = val.replaceAll(rm, '')
        })
        onChange && onChange(val, {
            isInvalid: val.length > 0 ? patternVal.includes(placeholder) : false,
            message: message || 'Invalid format'
        })
    }

    function PM_changeHandler(e) {
        if (!pattern) {
            let val = e.target.value
            onChange && onChange(val)
            setState({ val, index: [] })
        }
    }

    return { PM_changeHandler, PM_keyDownHandler, PM_value: state.val === pattern ? '' : state.val }
}