import React, { useEffect, useState } from 'react'
import './StatusBox.scss'
import useTranslate from '../../_Hooks/useTranslate'
import { classMaker } from '../..'

export default function StatusBox({ className, css, status = '', width, label, mb, mt, mr, ml }) {

  const { t } = useTranslate()

  function formatter(text) {
    return text?.toLowerCase().split(' ').join('')
  }

  return (
    <span id='StatusBox' className={classMaker(className, css, 'StatusBox')} style={{
      '--sbx-color': `var(--sbx-${formatter(status)}, #0000002a)`,
      '--sbx--color': `var(--sbx--${formatter(status)}, #0000002a)`,
      marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml
    }}>
      {label && <p>{label}</p>}
      <span className={'box' + ' ' + formatter(status)} style={{ width: width }}>
        {t(status)}
      </span>
    </span >
  )
}
StatusBox.displayName = 'StatusBox'