import React, { useEffect, useState } from 'react'
import './Header.scss'
import { useNavigate } from 'react-router-dom'
import Select from '../../_common/_components/Select/Select'
import Button from '../../_common/_components/Button/Button'
import ToolTip from '../../_common/_components/ToolTip/ToolTip'
import useTranslate from '../../_common/_Hooks/useTranslate'
import { IMG_BASE_URL } from '../../config'
import { AppLoggedOut, setApiHeaders, storageUpdate } from '../../_common'
import Notifications from '../../_common/_components/Notifications/Notifications'
import { useSelector } from 'react-redux'
import Image from '../../_common/_components/Image/Image'
import useFetch from '../../_common/_Hooks/useFetch'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import { NavBarLabelCounts } from '../../_common/_components/NavBar/NavBar'
import useModal from '../../_common/_Hooks/useModal'
import { Confirm } from '../../common/Modals'
import { socket } from '../InitialApis'

export default function Header() {

  const navigate = useNavigate()
  const { setLanguage, lang, _lang, t } = useTranslate()

  const [notifications, setNotifications] = useState([])

  const m_logoutConfirm = useModal(<Confirm />)

  const departments = useSelector(s => s.departments)
  const department = useSelector(s => s.department)
  const user = useSelector(s => s.user) || {}

  const roleData = {
    user: "User",
    manager: "Manager",
    sub_admin: "Sub Admin",
    super_admin: "Super Admin",
  }

  // *********************************************************************************************************************
  const { req: getNotifications, res: notifications_res } = useFetch({
    endpoint: 'notification/getAllNotification',
    method: 'post',
    type: 'raw',
    selfCall: { limit: 10 },
    privateLoader: true,
    dependency: [department],
    success: (res) => {
      res.page.page === 0 && setNotifications(res.data)
      NavBarLabelCounts && NavBarLabelCounts({
        Notifications: res.page.totalCount > 99 ? '+99' : res.page.totalCount,
        Alerts: res.alertCount > 99 ? '+99' : res.alertCount
      })
    }
  })

  // *********************************************************************************************************************
  const { req: readNotification } = useFetch({
    endpoint: 'notification/deleteNotification',
    method: 'post',
    type: 'raw',
    privateLoader: true
  })
  // *********************************************************************************************************************
  const { req: deleteAllNotifications } = useFetch({
    endpoint: 'notification/deleteAllNotification',
    method: 'post',
    type: 'raw',
    privateLoader: true
  })
  // *********************************************************************************************************************
  const { req: logout } = useFetch({
    endpoint: 'logout/userLogout',
    method: 'post',
    type: 'raw',
    toast: true,
    privateLoader: true
  })
  // *********************************************************************************************************************

  async function logoutHandler() {
    const { cancel } = await m_logoutConfirm.show({ action: 'Logout' })
    if (cancel) return
    logout({ refreshToken: localStorage.getItem('refreshToken'), userId: user._id })
    AppLoggedOut()
    navigate('/login')
    socket.disconnect()
  }

  function switchHandler(e) {
    setApiHeaders({ deptid: e._id })
    storageUpdate({ department: e })
    navigate(user?.role === 'viewer' ? 'dashboard_viewer' : '/')
  }

  function deleteHandler(item) {
    readNotification({ id: item._id }, () => {
      getNotifications()
    })
  }

  function onAllClearHandler() {
    deleteAllNotifications({}, () => {
      getNotifications()
    })
  }

  function loadMoreHandler() {
    getNotifications({ page: notifications_res?.page?.page + 1, limit: 10 }, (res) => {
      setNotifications(s => {
        return [...s, ...res.data]
      })
    })
  }

  useEffect(() => {
    document.body.classList.toggle("rtl", lang === 'ar');
    document.body.classList.toggle("ltr", lang !== 'ar');
  }, [lang, _lang]);

  return (
    <div id='Header'>

      <div className='logo'>
        <img src={'/assets/img/logo.svg'} alt="" onClick={() => navigate('/')} />
      </div>

      <div className='actions'>
        <div className="switches">
          {
            user?.is_english && <Button content={lang === 'en' ? 'AR' : 'EN'} css={2} onClick={() => { setLanguage(s => s === 'en' ? 'ar' : 'en') }} />
          }
          <Select
            css={1}
            field='switch_mode'
            width={250}
            placeholder="Switch to Dept"
            className='user-switch'
            label=''
            value={department}
            content={{
              op: departments,
              vl: '_id',
              lb: 'department_name' + _lang
            }}
            onChange={switchHandler}
            condition={departments?.length > 1}
          />
        </div>

        <div className="user_menu">
          {user?.role !== 'viewer' &&
            <Notifications className={'test_class'} content={notifications} count={notifications_res?.page.totalCount}
              onClear={deleteHandler} onClearAll={onAllClearHandler} onLoadMore={loadMoreHandler}
            >
              {
                (item, { popupClose }) => (
                  <span onClick={() => { item.request_id && navigate('/incident/myrequests/view_request/' + item.request_id); popupClose(); deleteHandler(item) }}>
                    <p className='title'>{item.title_en}</p>
                    <p className='message'>{<DateViewer dateTime={item.createdAt} />}</p>
                  </span>
                )
              }
            </Notifications>}

          <div className="user_details">
            <p className='name'>{user.first_name_en}</p>
            <p className='role'>{t(roleData[user.role])}</p>
          </div>

          <ToolTip className={'user_info'} anchor={<Image className='user_image' src={`${IMG_BASE_URL}profile/${user.profile_image}`} />} >
            <p className='detail dept_name'><em>{department?.['department_name' + _lang]}</em> : <span>{t('Department')}</span></p>
            <p className='detail emp_id'><em>{user.employee_id}</em> : <span>{t('Employee ID')}</span></p>
            <p className='logout' onClick={logoutHandler} >{t('Logout')}</p>
          </ToolTip>
        </div>
      </div>

    </div>
  )
}