import React, { useEffect } from 'react'

import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Page from '../../_common/_components/Page/Page'
import Table from '../../_common/_components/Table/Table'
import Select from '../../_common/_components/Select/Select'
import Button from '../../_common/_components/Button/Button'
import Image from '../../_common/_components/Image/Image'
import Actions from '../../_common/_components/Actions/Actions'
import FilterBox from '../../_common/_components/FilterBox/FilterBox'
import useFilter from '../../_common/_Hooks/useFilter'
import useFetch from '../../_common/_Hooks/useFetch'
import List from '../../_common/_components/List/List'
import { useNavigate } from 'react-router'
import { IMG_BASE_URL, SECRETKEY } from '../../config'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import useTranslate from '../../_common/_Hooks/useTranslate'
import cryptoJs from 'crypto-js'
import { v4 } from 'uuid'
import useModal from '../../_common/_Hooks/useModal'
import { ResetPassword } from '../../common/Modals'

export default function UserListing() {

    const navigate = useNavigate()
    const { t, _lang } = useTranslate()
    const uuid = v4()
    const ResetPasswordModal = useModal(<ResetPassword />)

    const roleData = [
        { label: "User", value: "user" },
        { label: "Manager", value: "manager" },
        { label: "Sub Admin", value: "sub_admin" },
    ]

    // *********************************************************************************************************************
    const { req: getUsers, res: users_res } = useFetch({
        endpoint: 'user/getAllUsers',
        method: 'post',
        type: 'raw',
        toast: false,
    })
    // *********************************************************************************************************************
    const { req: deleteUser } = useFetch({
        endpoint: 'user/userEnableOrDisable',
        method: 'put',
        type: 'params',
        toast: true,
    })
    // *********************************************************************************************************************
    const { res: department_res } = useFetch({
        endpoint: 'department/getDepartment',
        method: "post",
        type: "formdata",
        selfCall: true
    })
    // *********************************************************************************************************************
    const { req: resetPassword } = useFetch({
        endpoint: 'common/resetUsersPassword',
        method: "post",
        type: "body",
        toast: true
    })
    // *********************************************************************************************************************
    let users = users_res?.data
    let pageDetails = users_res?.page
    let departments = department_res?.data
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate, initialFilter } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getUsers
    })

    async function deleteHandler(id) {
        let { cancel } = await Modals.Confirm({ action: 'Delete' })
        if (cancel) return
        deleteUser(id, () => getUsers(initialFilter))
    }

    async function resetPasswordHandler(u) {
        let { cancel, m_inputs } = await ResetPasswordModal.show()
        if (cancel) return
        resetPassword({
            id: u._id,
            password: cryptoJs.AES.encrypt(m_inputs.pwd, uuid).toString() + '_' + window.btoa(uuid + '_' + u.employee_id)
        })
    }

    return (
        <Page>

            <Page.Header>
                <Title title='Manage Users' />
                <Breadcrumb />
                <Button content='Add User' css={1.1} icon={'/assets/icons/plus.svg'} reverse onClick={() => navigate('add_user')} />
            </Page.Header>

            <Page.Body css={1} >
                <FilterBox
                    _css={{ Select: 1, DatePick: 1, Input: 1, Search_Btn: 1.1, Clear_Btn: 1.2 }}
                    _placeholder={{ Select: 'Select', Input: 'Type in input' }}
                    showCloseBtns
                    filterHandler={filterHandler}
                    filterData={filterData}
                >
                    <Select label='Department' field='departmentId' content={{ op: departments, lb: 'department_name' + _lang, vl: '_id' }} />
                    <Select label='Role' field='role' content={{ op: roleData, lb: 'label', vl: 'value' }} />
                </FilterBox>
                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Photo', key: 'photo' },
                        { title: 'Employee Name', key: 'first_name', sort: true },
                        { title: 'Employee ID', key: 'employee_id', sort: true },
                        { title: 'Role', key: 'role', sort: true },
                        { title: 'Department', key: 'department', sort: true },
                        { title: 'Agent', key: 'agent', sort: true },
                        { title: 'Action', key: 'action' }
                    ]}
                    content={users?.map((u => ({
                        _id: u._id,
                        photo: <Image src={`${IMG_BASE_URL}/profile/${u.profile_image}`} />,
                        first_name: u['first_name' + _lang],
                        employee_id: u.employee_id,
                        role: t(u.role),
                        department: <List items={u.department} item={(e) => e['department_name' + _lang]} />,
                        agent: t(u.is_agent ? 'Yes' : 'No'),
                        action: <Actions buttons={{ edit: '/assets/icons/edit.svg', delete: '/assets/icons/delete.svg', resetPwd: '/assets/icons/resetPwd.svg' }}
                            tooltip={{ resetPwd: 'Reset Password' }}
                            onEdit={() => F_navigate('/team/manage_users/edit_user/' + u._id)}
                            onDelete={() => { deleteHandler(u._id) }}
                            onResetPwd={() => { resetPasswordHandler(u) }}
                        />,
                    })))}
                    onRowClick={(e) => F_navigate('/team/manage_users/edit_user/' + e._id)}
                    filterData={filterData}
                    filterHandler={filterHandler}
                    pageDetails={pageDetails}
                />
            </Page.Body>

        </Page>
    )
}