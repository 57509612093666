import React from 'react'

export default function NetworkLost({ retry }) {

    return (
        <div id='NetworkLost'>
            <p>Network Connection Lost</p>
            <button onClick={retry}> Retry </button>
        </div>
    )
}
