import React, { useState } from 'react'
import './ArrayCollector.scss'
import Input from '../Input/Input'
import Button from '../Button/Button'

export default function ArrayCollector({ onDelete, _css = { input: 3, button: 1 }, value, elements = [{ key: 'array_item' }], onChange }) {

    const [items, setItems] = useState(value?.length > 0 ? value : [{}])

    function deleteHandler(index) {
        setItems(s => {
            let res = s.filter((item, i) => {
                if (i === index) {
                    onDelete && onDelete(item)
                    return false
                }
                return true
            })
            if (res.length === 0) {
                onChange && onChange([])
                return [{}]
            }
            else {
                onChange && onChange(res)
                return res
            }
        })
    }

    function inputHandler(value, key, index) {
        setItems(s => {
            let obj = Object.assign({}, s)
            obj[index] = {
                ...obj[index],
                [key]: value
            }
            let res = Object.values(obj)
            onChange && onChange(res)
            return res
        })
    }

    function addHandler() {
        setItems(s => [...s, {}])
    }

    return (
        <div id='ArrayCollector'>
            <div className='header'>
                {
                    elements.map((elem, j) => {
                        return (
                            <p>{elem.title}</p>
                        )
                    })
                }
            </div>
            {items.map((item, i) => {
                return (
                    <div className='array_collector_item' key={i}>
                        {
                            elements.map((elem, j) => {
                                return (
                                    <Input key={j} css={_css.input} field={elem.key} value={item[elem.key]} placeholder='-' onChange={(v, k) => inputHandler(v, k, i)} />
                                )
                            })
                        }
                        <img className='btn_delete' width={15} src="/assets/icons/delete.svg" alt="" onClick={() => deleteHandler(i)} />
                    </div>
                )
            })}
            <Button css={_css.button} className='btn_add' content={'Add Item'} onClick={addHandler} />
        </div>
    )
}