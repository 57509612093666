import React, { useEffect, useState } from 'react'
import './ModalComponent.scss'
import { classMaker } from '../..'

export default function ModalComponent({ className, css, show, style, children, onBackdropClick }) {

    const [remove, setRemove] = useState(true)

    function handleParentClick(e) {
        let closeBtn = e.target.querySelector('#m_close_btn')
        let hasCloseBtn = closeBtn && closeBtn.offsetHeight !== 0 && closeBtn.offsetWidth !== 0
        if (e.target === e.currentTarget) {
            if (!hasCloseBtn) {
                onBackdropClick && onBackdropClick()
            }
        }
    }

    useEffect(() => {
        if (show === true) { setRemove(false) }
        else {
            setTimeout(() => {
                setRemove(true)
            }, 500);
        }
    }, [show])

    return (
        <div id='Modal_backdrop'
            className={classMaker(className, css, 'Modal') + (show ? ' display' : ' display-none') + (remove ? ' hide' : '')}
            onClick={handleParentClick}
        >
            <div id='Modal' style={style}>
                {show && children}
            </div>
        </div>
    )
}