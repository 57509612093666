import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import useFetch from '../../_common/_Hooks/useFetch'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import Grid from '../../_common/_components/Grid/Grid'
import InstaGridItem from '../../components/InstaGridItem/InstaGridItem'

export default function InstaFeeds() {

    // *********************************************************************************************************************
    const { res: instagram_feeds_res } = useFetch({
        endpoint: 'instagram/listInstagramFeeds',
        method: 'post',
        type: 'raw',
        selfCall: { isDashboard: true }
    })
    // *********************************************************************************************************************
    let instagram_feeds = instagram_feeds_res?.data
    // *********************************************************************************************************************

    return (
        <Page className='InstaFeeds'>
            <Page.Header>
                <Title title="Instagram Feeds" />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title='Instagram Feeds'>
                    <Grid columns={3} css={1}>
                        {
                            instagram_feeds?.map((datom, i) => {
                                return <InstaGridItem data={datom} wholeData={instagram_feeds} index={i} />
                            })
                        }
                    </Grid>
                </TitleBar>
            </Page.Body>
        </Page>
    )
}
