import React from 'react'
import './InstaGridItem.scss'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import { IMG_BASE_URL } from '../../config'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import useTranslate from '../../_common/_Hooks/useTranslate'

export default function InstaGridItem({ data, wholeData, index }) {

    const { t } = useTranslate()

    let fileType = data.instagram_post[0].split('.').pop()
    let filesCount = data.instagram_post.length
    let file = data.instagram_post[0]

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} style={{ fontSize: '12px' }}>
            {props.label}
        </Tooltip>
    );

    const sts = {
        waiting_approval: 'Waiting Approval',
        rejected: 'Rejected',
        approved: 'Approved',
        change_requested: 'Change Requested',
    }

    return (
        <div id='InstaGridItem' className='insta_feed'>
            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip({ label: t(sts[data.status.handle_key]) })} >
                <div className="tag">
                    {
                        data.status.handle_key === 'waiting_approval' ? <img src="/assets/icons/icon_processing.svg" alt="" />
                            : data.status.handle_key === 'rejected' ? <img src="/assets/icons/icon_rejected.svg" alt="" />
                                : data.status.handle_key === 'approved' ? <img src="/assets/icons/icon_waitingforapproval.svg" alt="" />
                                    : data.status.handle_key === 'change_requested' ? <img src="/assets/icons/icon_new.svg" alt="" />
                                        : null
                    }
                </div>
            </OverlayTrigger>
            {
                filesCount > 1
                    ? <img className='icon' src="/assets/icons/insta_multiple.svg" alt="" />
                    : fileType === 'mp4' && <img className='icon' src="/assets/icons/insta_reels.svg" alt="" />
            }
            {
                fileType === 'mp4'
                    ? <video className='feed' src={`${IMG_BASE_URL}/profile/${file}`} alt=""
                        onClick={() => Modals.InstagramPosts({ data: wholeData, index })}
                    />
                    : <img className='feed' src={`${IMG_BASE_URL}/profile/${file}`} alt=""
                        onClick={() => Modals.InstagramPosts({ data: wholeData, index })}
                    />
            }
        </div>
    )
}
