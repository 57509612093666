import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AppContext } from '../App'
import config from '../../common/config'
import useInactivity from './useInactivity'

export default function useInitialAPIs({ waitingApis, nonWaitingApis, addTranslation, onInActive, onActive }) {

    const { errorPages, got_call_from_useInitialAPIs } = useContext(AppContext)

    useInactivity({ onInActive, onActive })

    const [moveOn, setMoveOn] = useState(false)

    useEffect(() => {
        if (!got_call_from_useInitialAPIs) {
            Promise
                .all(waitingApis.map(api => {
                    return api()
                }))
                .then(res => {
                    setMoveOn(true)
                }).catch(err => {
                    setMoveOn(true)
                })
            Promise
                .all(nonWaitingApis.map(api => {
                    return api()
                }))
        } else {
            setMoveOn(true)
        }
    }, [])

    return moveOn
        ? <Outlet context={{ addTranslation }} />
        : <div className='css_oth'>{config._loader || errorPages.Loader}</div>
}
