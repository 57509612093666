import React, { useEffect, useState } from 'react'
import './ActionButtons.scss'
import { classMaker } from '../..'
import Select from '../Select/Select'
import Button from '../Button/Button'
import icon_back from './acb_back.svg'
import { useNavigate } from 'react-router-dom'

export default function ActionButtons({ className = '', css, _css = { button: '', select: '' }, actions, selectContent = { vl: '', lb: '' }, onAction, filter, children }) {

  const navigate = useNavigate()

  actions = filter ? actions?.filter(filter) : actions

  return (
    <div id='ActionButtons' className={classMaker(className, css, 'ActionButtons')}>
      <div>
        <Button css={_css?.button} content='Back' icon={icon_back} reverse onClick={() => navigate(-1)} />
      </div>
      <div>
        {actions?.length > 3
          ? <>
            <Select css={_css?.select} height={45} content={{ op: actions, ...selectContent }} field='action' onChange={(e) => onAction(e)} />
            <Button css={_css?.button} content='Save' onClick={() => onAction({ save: true })} />
          </>
          : actions?.map((action, i) => {
            return <Button key={i} css={_css?.button} content={action.action_status} onClick={() => onAction(action)} />
          })
        }
        {children}
      </div>
    </div>
  )
}