import React from 'react'
import './ReportCounts.scss'
import icon_needinfo from './icon_needinfo.svg'
import icon_draft from './icon_draft.svg'
import icon_rejected from './icon_rejected.svg'
import icon_escalated from './icon_escalated.svg'
import icon_processing from './icon_processing.svg'
import icon_closed from './icon_closed.svg'
import icon_resolved from './icon_resolved.svg'
import icon_waitingforapproval from './icon_waitingforapproval.svg'
import icon_todo from './icon_todo.svg'
import icon_new from './icon_new.svg'
import icon_forward from './icon_forward.svg'
import icon_assign from './icon_assign.svg'
import icon_cancel from './icon_cancel.svg'
import icon_complete from './icon_complete.svg'
import icon_confirm from './icon_confirm.svg'
import icon_count from './icon_count.svg'
import icon_not_respond from './icon_not_respond.svg'
import icon_review from './icon_review.svg'
import icon_reopen from './icon_reopen.svg'
import Flex from '../../_common/_components/Flex/Flex'
import useTranslate from '../../_common/_Hooks/useTranslate'


export default function ReportCounts({ data }) {

    const { t } = useTranslate()

    const content = [
        { title: 'Need Info', icon: icon_needinfo },
        // { title: 'Draft', icon: icon_draft },
        { title: 'Rejected', icon: icon_rejected },
        { title: 'Forwarded', icon: icon_forward },
        { title: 'Assigned', icon: icon_assign },
        { title: 'Escalated', icon: icon_escalated },
        { title: 'Processing', icon: icon_processing },
        { title: 'Closed', icon: icon_closed },
        { title: 'Resolved', icon: icon_resolved },
        { title: 'Reopened', icon: icon_reopen },
        { title: 'Waiting For Approval', icon: icon_waitingforapproval },
        { title: 'To Do', icon: icon_todo },
        { title: 'New', icon: icon_new },
        { title: 'Cancelled', icon: icon_cancel },
        { title: 'Completed', icon: icon_complete },
        { title: 'Confirmed', icon: icon_confirm },
        { title: 'Not Responded', icon: icon_not_respond },
        { title: 'To Be Reviewed', icon: icon_review },
        { title: 'Total', icon: icon_count },
    ]

    return (
        <div id='ReportCounts'>
            <Flex columns={7} gap={10} _minWidth={200}>
                {
                    content?.map((c, i) => {
                        if (data[c.title] === undefined) return
                        return (
                            <div key={i} className='item'>
                                <span>
                                    <img src={c.icon} alt="" />
                                    <p className='title'>{t(c.title)}</p>
                                </span>
                                <p className='count'>{data[c.title]}</p>
                            </div>
                        )
                    })
                }
            </Flex>
        </div>
    )
}