import React, { useEffect, useRef, useState } from 'react'
import './ToolTip.scss'

export default function ToolTip({ className, anchor, children }) {

    const ref = useRef()

    const [active, setActive] = useState(false)

    function clickHandler(e) {
        setActive(s => !s)
    }

    function eventHandler(e) {
        let anchor = ref.current?.querySelector('.ToolTip_anchor')
        let toolTip = ref.current?.querySelector('.ToolTip_float')
        if (toolTip) {
            if (!toolTip.contains(e.target) && e.target !== anchor && !anchor.contains(e.target)) {
                setActive(false);
            }
        }
    }

    useEffect(() => {
        document.addEventListener("click", eventHandler)
        document.addEventListener("scroll", eventHandler)
        return () => {
            document.removeEventListener("click", eventHandler)
            document.removeEventListener("scroll", eventHandler)
        }
    }, [])

    function popupClose() {
        setActive(false)
    }

    return (
        <span id='ToolTip' ref={ref}>
            {anchor && React.cloneElement(anchor, {
                className: 'ToolTip_anchor ' + (anchor.props?.className || ''),
                onClick: clickHandler,
            })}
            {children && active &&
                <div className={"ToolTip_float " + className}>
                    {children instanceof Function ? children({ popupClose }) : children}
                </div>
            }
        </span>
    )
}